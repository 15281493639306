import React, { useState } from "react";

import "./style.scss";
import cx from "utils/cx";

const owlClass = "button-group";
const NBUTTON = 2;

type GroupButton = {
  index: number;
  text: string;
  badge?: string;
  onClick?: () => void;
};

interface ButtonGroupProps {
  buttons: GroupButton[];
}

const ButtonGroup = ({ buttons }: ButtonGroupProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const slideBg = (index: number, func: (() => void) | undefined) => {
    const owl: HTMLDivElement | null = document.querySelector(`.${owlClass}`);
    if (owl) {
      setActiveIndex(index);
      const bgOffset = ((100 * 1.0) / NBUTTON) * index;
      owl.style.setProperty("--bg-offset", `${bgOffset}%`);
      func?.();
    }
  };

  return (
    <div
      className={owlClass}
      style={
        {
          "--width-btn": `${(100 * 1.0) / NBUTTON}%`,
        } as React.CSSProperties
      }
    >
      {buttons
        .sort((a, b) => a.index - b.index)
        .map((button) => (
          <button
            key={button.index}
            onClick={() => slideBg(button.index, button?.onClick)}
            className={activeIndex === button.index ? "active" : ""}
          >
            <span
              className={cx("grpbtn", activeIndex === button.index && "active")}
            >
              <span>{button.text}</span>
              {button.badge && <span className="badge">{button.badge}</span>}
            </span>
          </button>
        ))}
    </div>
  );
};

export default ButtonGroup;
