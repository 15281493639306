import React from "react";

import { useAppDispatch } from "hooks";
import { onScrollTo } from "store/home";

import Title from "components/title";
import Button from "components/button";

import "./style.scss";

interface BannerTrialProps {}

const owlClass = "banner-trial";

const BannerTrial: React.FC<BannerTrialProps> = () => {
  const dispatch = useAppDispatch();
  return (
    <div className={owlClass}>
      <Title
        size="h2"
        center
        color="white"
        style={{
          fontSize: 28,
          lineHeight: "normal",
          fontWeight: 600,
        }}
      >
        Đăng ký và trải nghiệm miễn phí gói zBusiness Basic
      </Title>
      <Button
        size="x-large"
        onClick={() => dispatch(onScrollTo("explore-homepage"))}
      >
        Khám phá ngay
      </Button>
    </div>
  );
};

export default BannerTrial;
