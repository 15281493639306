import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import home from "./home";
import profile from "./profile";
import subscription from "./subscription";

const reducer = combineReducers({
  // here we will be adding reducers
  home,
  profile,
  subscription,
});
export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
