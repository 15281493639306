import type { IconProps } from "./model.js";

const CloseIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "16"}
    height={size || "16"}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.43369 0.186892C1.08499 -0.0842409 0.58078 -0.0595924 0.26035 0.260838C-0.0867832 0.607971 -0.0867832 1.17078 0.26035 1.51792L6.74292 8.00049L0.26035 14.4831L0.186404 14.5668C-0.0847291 14.9155 -0.0600807 15.4197 0.26035 15.7401C0.607482 16.0873 1.1703 16.0873 1.51743 15.7401L8 9.25757L14.4826 15.7401L14.5663 15.8141C14.915 16.0852 15.4192 16.0606 15.7397 15.7401C16.0868 15.393 16.0868 14.8302 15.7397 14.4831L9.25708 8.00049L15.7397 1.51792L15.8136 1.43418C16.0847 1.08547 16.0601 0.581268 15.7397 0.260838C15.3925 -0.0862949 14.8297 -0.0862949 14.4826 0.260838L8 6.74341L1.51743 0.260838L1.43369 0.186892Z"
      fill="#0068FF"
    />
  </svg>
);

export default CloseIcon;
