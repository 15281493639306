import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Text from "components/text";
import RegisterPackage from "components/register-package";
import Button from "components/button";

// utils
import { IS_TRIAL, Z_BUSINESS, TRIAL_PRODUCT_ID } from "constant/home";

import "./style.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { onScrollTo } from "store/home";
import home from "services/home";

import { RootState } from "store";
import { CheckIcon } from "icons";

interface PricePackagesProps {}

const owlClass = "price-packages";

const TAG = {
  param: "tag",
  value: "trial",
};

const PricePackages: React.FC<PricePackagesProps> = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const tag = searchParams.get(TAG.param);
  const [errorCodeResp, setErrorCodeResp] = useState<string>("");

  const { subscription } = useAppSelector(
    (state: RootState) => state.subscription
  );

  useEffect(() => {
    const resp = searchParams.get("resp");
    if (resp) {
      setErrorCodeResp(resp);
    }
  }, []);

  useEffect(() => {
    if (
      !subscription ||
      !subscription.basic ||
      !subscription.pro ||
      !subscription.standard
    ) {
      return;
    }
    if (subscription.basic.focus) {
      Z_BUSINESS.packages[0].isRecommend = true;
    } else Z_BUSINESS.packages[0].isRecommend = false;

    if (subscription.standard.focus) {
      Z_BUSINESS.packages[1].isRecommend = true;
    } else Z_BUSINESS.packages[1].isRecommend = false;

    if (subscription.pro.focus) {
      Z_BUSINESS.packages[2].isRecommend = true;
    } else Z_BUSINESS.packages[2].isRecommend = false;
  }, [subscription]);

  return (
    <div className={owlClass}>
      <div className={`${owlClass}__container`}>
        {Z_BUSINESS.packages
          .filter((pkg) => pkg.packageId !== "standard")
          .map(
            ({
              isRecommend,
              packageId,
              name,
              products,
              priceDuration,
              extraLabels,
              payType,
              includes,
            }) => (
              <div
                className={
                  isRecommend
                    ? `${owlClass}__container__package ${owlClass}__container__package--activate`
                    : `${owlClass}__container__package`
                }
                key={name}
              >
                <div className={`${owlClass}__container__package__top`}>
                  <Text
                    size="xlg-36"
                    weight="three-quarters-bold"
                    style={{
                      fontSize: 36,
                    }}
                  >
                    {name}
                  </Text>
                  <Text
                    size="md"
                    color="secondary"
                    className={`${owlClass}__container__package__top__pay-type`}
                  >
                    {payType}
                  </Text>
                  <Text size="xlg-28" weight="bold" style={{marginBottom: 32}}>
                    {priceDuration}
                  </Text>
                  {extraLabels && !!extraLabels.length && (
                    <div
                      className={`${owlClass}__container__package__top__extra-labels`}
                    >
                      {extraLabels &&
                        !!extraLabels.length &&
                        extraLabels.map((extraLabel, idx) => (
                          <div key={idx}>{extraLabel}</div>
                        ))}
                    </div>
                  )}
                  <div>
                    <RegisterPackage
                      isTrial={packageId === IS_TRIAL}
                      isOpenTrialModalLoggedIn={
                        tag === TAG.value &&
                        subscription?.basic.products.find(
                          (pro) => pro.productId === TRIAL_PRODUCT_ID
                        )?.canBuy
                      }
                      errorCodeModalLoggedIn={errorCodeResp}
                      name={name}
                      products={products.filter(prod => !prod.hidden)}
                      isBlock
                      size="large"
                      source="landing-page"
                    />
                  </div>
                </div>
                <div className={`${owlClass}__container__package__bottom`}>
                  {includes.map((include, idx) => (
                    <div
                      key={idx}
                      className={`${owlClass}__container__package__bottom__item`}
                    >
                      <CheckIcon color="#3576EB" style={{flexShrink: 0}}/>
                      {include}
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
      </div>
      <div className={`${owlClass}__compare-products`}>
        <Button
          size="large"
          onClick={() => {
            home.trackingLog({
              flow: "Onboarding",
              action: "click_button_compare_package_and_feature",
            });
            dispatch(onScrollTo("compare-packages"));
          }}
        >
          So sánh các gói và tính năng
        </Button>
      </div>
    </div>
  );
};

export default PricePackages;
