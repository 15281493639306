import type { IconProps } from "./model.js";

const NoneIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "24"}
    height={size || "24"}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="11" width="18" height="2" rx="1" fill="#A3A3A3" />
  </svg>
);

export default NoneIcon;
