import React, { useMemo } from "react";

import "./style.scss";

interface SpinProps {
  isSmall?: boolean;
  isDanger?: boolean;
}

const owlClass = "spin";

const Spin: React.FC<SpinProps> = ({ isSmall, isDanger }) => {
  const styleClass = useMemo(() => {
    let style = owlClass;
    if (isSmall) {
      style = style + ` ${owlClass}--small`;
    }
    if (isDanger) {
      style = style + ` ${owlClass}--danger`;
    }

    return style;
  }, [isSmall, isDanger]);
  return <div className={styleClass} />;
};

export default Spin;
