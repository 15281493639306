import React, { useMemo } from "react";

import Text from "components/text";

import "./style.scss";
import ErrorIcon from "icons/ErrorIcon";
import useWindowSize from "hooks/use-window-size";

interface ErrorProps {
  title: string;
  description?: string;
  txtBtn1?: React.ReactNode;
  txtBtn2?: React.ReactNode;
}

const owlClass = "error-page";

const Error: React.FC<ErrorProps> = ({
  title,
  description,
  txtBtn1,
  txtBtn2,
}) => {
  const TABLET_SIZE = 768;

  const { width } = useWindowSize();
  const isDesktop = useMemo(() => width > TABLET_SIZE, [width]);
  return (
    <div className={owlClass}>
      <ErrorIcon size={isDesktop ? 96 : 72} className={`${owlClass}__icon`} />
      <Text size="xlg-24" center>
        {title}
      </Text>
      <Text color="secondary" center>
        {description}
      </Text>
      {!!txtBtn2 && txtBtn2}
      {!!txtBtn1 && txtBtn1}
    </div>
  );
};

export default Error;
