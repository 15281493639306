import React, { useCallback, useEffect, useRef, useState } from "react";

import "./style.scss";
import { AddIcon, MinusIcon } from "icons";
import cx from "utils/cx";
import Text from "components/text";
import useLongPress from "hooks/useLongPress";

interface CounterProps {
  value: number;
  min?: number;
  max?: number;
  onUpdate: (value: number) => void;
  errorMessage?: string;
}
const specialCharacterPattern = /[^\d]+/g;
const owlClass = "counter";
let timer: ReturnType<typeof setTimeout>;
const Counter: React.FC<CounterProps> = ({
  value,
  min = 1,
  max = 100,
  onUpdate,
  errorMessage,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(value < min ? "" : `${value}`);
  const [showError, setShowError] = useState<boolean>(false);

  const onMinus = useCallback(() => {
    if (value - 1 < min) {
      return;
    }
    if (value < min) {
      onUpdate(min);
      setInputValue(`${min}`);
    } else {
      onUpdate(value - 1);
      setInputValue(`${value - 1}`);
    }
  }, [value, min, onUpdate]);
  const onMinLongPress = useLongPress(onMinus, 100, 500);

  const onAdd = useCallback(() => {
    if (value + 1 > max) {
      setShowError(true);
      return;
    }
    if (value > max) {
      onUpdate(max);
      setInputValue(`${max}`);
    } else {
      onUpdate(value + 1);
      setInputValue(`${value + 1}`);
    }
  }, [value, max]);
  const onMaxLongPress = useLongPress(onAdd, 100, 500);

  useEffect(() => {
    if (showError) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setShowError(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showError]);

  const handleChange = useCallback(
    (event: any) => {
      try {
        let value = event.target.value.replace(specialCharacterPattern, "");
        if (/^0/.test(value)) {
          value = value.replace(/^0+/, "");
        }
        setInputValue(value);
        if (value === "") {
          onUpdate(0);
          return;
        }
        const newValue = parseInt(value, 10);
        if (newValue < min || newValue > max) {
          setShowError(true);
        }
        if (newValue > max) {
          setInputValue(`${max}`);
          onUpdate(max);
        } else {
          onUpdate(newValue);
        }
      } catch (err) {
        // Ignored
      }
    },
    [min, max, showError, inputValue]
  );

  return (
    <div className={`${owlClass}`}>
      <div
        className={cx(
          `${owlClass}__btn__minus`,
          value <= min && `${owlClass}__btn__disabled`
        )}
        onClick={onMinus}
        {...onMinLongPress}
      >
        <MinusIcon />
      </div>
      <div className={`${owlClass}__value`}>
        <input
          ref={inputRef}
          className={cx(`${owlClass}__input`)}
          value={inputValue}
          pattern="\d*"
          inputMode="numeric"
          onChange={handleChange}
        />
      </div>
      <div
        className={cx(
          `${owlClass}__btn__add`,
          value >= max && `${owlClass}__btn__disabled`
        )}
        onClick={onAdd}
        {...onMaxLongPress}
      >
        <AddIcon />
      </div>
      {errorMessage && (
        <div
          className={cx(
            `${owlClass}__content`,
            showError && `${owlClass}__active`
          )}
        >
          <Text color="white">{errorMessage}</Text>
        </div>
      )}
    </div>
  );
};

export default Counter;
