import type { IconProps } from "./model.js";

const MenuIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "25"}
    height={size || "24"}
    viewBox="0 0 25 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3613 5.00049H3.86133C3.11133 5.00049 2.61133 5.40049 2.61133 6.00049C2.61133 6.60049 3.11133 7.00049 3.86133 7.00049H21.3613C22.1113 7.00049 22.6113 6.60049 22.6113 6.00049C22.6113 5.40049 22.1113 5.00049 21.3613 5.00049ZM21.3613 11.0005H3.86133C3.11133 11.0005 2.61133 11.4005 2.61133 12.0005C2.61133 12.6005 3.11133 13.0005 3.86133 13.0005H21.3613C22.1113 13.0005 22.6113 12.6005 22.6113 12.0005C22.6113 11.4005 22.1113 11.0005 21.3613 11.0005ZM3.86133 17.0005H21.3613C22.1113 17.0005 22.6113 17.4005 22.6113 18.0005C22.6113 18.6005 22.1113 19.0005 21.3613 19.0005H3.86133C3.11133 19.0005 2.61133 18.6005 2.61133 18.0005C2.61133 17.4005 3.11133 17.0005 3.86133 17.0005Z"
      fill="#0068FF"
    />
  </svg>
);

export default MenuIcon;
