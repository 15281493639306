import type { IconProps } from "./model.js";

const ConsultantSellIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "64"}
    height={size || "64"}
    {...props}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={size || "64"} height={size || "64"} rx="32" fill="white" />
    <rect
      width={size || "64"}
      height={size || "64"}
      rx="32"
      fill="url(#paint0_linear_7104_55616)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.6778 47.0418L29.6789 47.0409L29.6918 47.0301C29.7041 47.0197 29.7235 47.0031 29.7486 46.9806C29.799 46.9355 29.8716 46.8676 29.9558 46.7803C30.1259 46.6039 30.3338 46.3579 30.5016 46.0703C30.8341 45.5003 30.9705 44.8585 30.4845 44.2053C29.3477 42.7814 28.5796 40.9654 28.5796 38.9464C28.5796 34.1377 32.7054 30.3413 37.6328 30.3413C42.5602 30.3413 46.686 34.1377 46.686 38.9464C46.686 43.755 42.5602 47.5514 37.6328 47.5514C36.7849 47.5514 36.0251 47.457 35.2654 47.2674C34.7125 47.138 34.4081 47.2354 33.7811 47.4359L33.6387 47.4814C32.8924 47.7183 31.8732 47.9996 30.0138 47.9996C29.7854 47.9996 29.5819 47.8553 29.5063 47.6398C29.4308 47.4246 29.4992 47.1853 29.6769 47.0425L29.6769 47.0425L29.6773 47.0422L29.6775 47.042L29.6778 47.0418ZM33.5096 37.6018C33.5096 37.3048 33.7503 37.064 34.0474 37.064H41.2182C41.5153 37.064 41.7561 37.3048 41.7561 37.6018C41.7561 37.8988 41.5153 38.1396 41.2182 38.1396H34.0474C33.7503 38.1396 33.5096 37.8988 33.5096 37.6018ZM33.5096 40.7391C33.5096 40.442 33.7503 40.2013 34.0474 40.2013H38.1706C38.4677 40.2013 38.7084 40.442 38.7084 40.7391C38.7084 41.0361 38.4677 41.2769 38.1706 41.2769H34.0474C33.7503 41.2769 33.5096 41.0361 33.5096 40.7391Z"
      fill="#0068FF"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4734 38.0412L18.4749 38.04L18.4921 38.0259C18.5086 38.0122 18.5346 37.9902 18.5682 37.9606C18.6356 37.9012 18.7328 37.8116 18.8453 37.6965C19.073 37.4638 19.3507 37.1396 19.574 36.7606C20.0156 36.0112 20.1955 35.1656 19.5345 34.302C17.9506 32.3864 17.001 29.9742 17.001 27.3569C17.001 21.0498 22.4685 16 29.1392 16C35.8098 16 41.2773 21.0498 41.2773 27.3569C41.2773 33.664 35.8098 38.7138 29.1392 38.7138C28.0321 38.7138 26.9762 38.576 25.9607 38.3261C25.199 38.1398 24.7924 38.2714 23.9448 38.5459L23.7764 38.6003C22.78 38.921 21.4204 39.3053 18.9239 39.3053C18.6176 39.3053 18.3448 39.1149 18.2434 38.8305C18.1422 38.5465 18.2339 38.2307 18.4721 38.0422L18.4722 38.0422L18.4722 38.0422L18.4727 38.0418L18.4729 38.0416L18.473 38.0416L18.4734 38.0412ZM29.9882 26.4969H32.5419C33.1583 26.4969 33.5105 27.2265 33.1362 27.7393L28.7994 33.8359C28.3444 34.4788 27.3758 34.0743 27.4638 33.2797L28.2857 28.8084H25.732C25.1229 28.8084 24.7707 28.0788 25.1376 27.566L29.4745 21.4694C29.9295 20.8265 30.8981 21.231 30.81 22.0256L29.9882 26.4969Z"
      fill="#0068FF"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7104_55616"
        x1="32"
        y1="64"
        x2="32.1075"
        y2="0.000182708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3591E6" stopOpacity="0" />
        <stop offset="1" stopColor="#3591E6" stopOpacity="0.2" />
      </linearGradient>
    </defs>
  </svg>
);

export default ConsultantSellIcon;
