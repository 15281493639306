import type { IconProps } from "./model.js";

const CheckIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "24"}
    height={size || "24"}
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15.17L17.8804 6.28957C18.2719 5.89807 18.9074 5.89958 19.2975 6.29246C19.6857 6.68339 19.6849 7.31502 19.2954 7.70457L9.70652 17.2934C9.31622 17.6837 8.68351 17.684 8.29289 17.294L3.71079 12.7196C3.31874 12.3282 3.32008 11.6921 3.71319 11.3018C4.10381 10.9139 4.73494 10.9142 5.12461 11.303L9 15.17Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckIcon;
