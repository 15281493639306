import React, { useEffect } from "react";

import Layout from "components/layout";
import Text from "components/text";
import LogoZBusiness from "components/logo-zbusiness";

import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks";
import { onScrollTo } from "store/home";

interface ServiceAgreementProps {}

const owlClass = "service-agreement";

const ServiceAgreement: React.FC<ServiceAgreementProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <Layout
      header={
        <LogoZBusiness
          onClick={() => {
            const canGoBack = location.key !== "default";
            if (canGoBack) {
              navigate(-1);
            } else {
              dispatch(onScrollTo("homepage-logo"));
              navigate("/");
            }
          }}
        />
      }
    >
      <div className={owlClass}>
        <div className={`${owlClass}__title`}>
          <Text size="xlg-22" weight="bold">
            THỎA THUẬN SỬ DỤNG DỊCH VỤ ZBUSINESS
          </Text>
        </div>
        <div className={`${owlClass}__terms`}>
          {/*  ĐIỀU 1. GIẢI THÍCH TỪ NGỮ */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title`}
              size="xlg-20"
              weight="bold"
            >
              GIẢI THÍCH TỪ NGỮ
            </Text>
            <ol className={`${owlClass}__terms__term__ol`}>
              {/* 1.1 */}
              <li>
                VNG: là Công Ty Cổ Phần VNG.
                <ol className={`${owlClass}__terms__term__ol`}>
                  {/* 1.2 */}
                  <li>
                    Zalo: là ứng dụng OTT đa nền tảng được phát triển bởi Công
                    Ty Cổ Phần VNG.{" "}
                  </li>
                  {/* 1.3 */}
                  <li>
                    Zalo ID: là tài khoản để Người Sử Dụng/Sử Dụng đăng nhập và
                    sử dụng các tính năng nâng cao được cung cấp.
                  </li>
                  {/* 1.4 */}
                  <li>
                    Thỏa Thuận: là thỏa thuận cung cấp và sử dụng Dịch Vụ
                    zBusiness trên Zalo, cùng với tất cả các bản sửa đổi, bổ
                    sung, cập nhật.
                  </li>
                  {/* 1.5 */}
                  <li>
                    Thông Tin Cá Nhân: là thông tin gắn liền với việc xác định
                    danh tính, nhân thân của cá nhân bao gồm họ và tên; ngày
                    tháng năm sinh; địa chỉ; số Chứng minh nhân dân/Thẻ Căn cước
                    công dân/Hộ chiếu, ngày cấp, nơi cấp; số điện thoại, địa chỉ
                    thư điện tử (email), tài khoản ngân hàng của Người Sử Dụng
                    và một số thông tin khác theo quy định của pháp luật.
                  </li>
                  {/* 1.6 */}
                  <li>
                    Sở Hữu Trí Tuệ: là những sáng chế, cải tiến, thiết kế, quy
                    trình, công thức, phương pháp, cơ sở dữ liệu, thông tin, bản
                    vẽ, mã, chương trình máy tính, tác phẩm có bản quyền (hiện
                    tại và tương lai), thiết kế mạch tích hợp bán dẫn, thương
                    hiệu, nhãn hiệu (dù đã đăng ký hay chưa đăng ký) tên thương
                    mại và (thiết kế) bao bì thương phẩm.
                  </li>
                  {/* 1.7 */}
                  <li>
                    Người Sử Dụng: là Người Sử Dụng đáp ứng đầy đủ điều kiện
                    theo Điều 4 và các quy định của Thỏa Thuận này và các quy
                    định pháp luật có liên quan để đăng ký sở hữu, sử dụng tài
                    khoản Zalo ID.
                  </li>
                  {/* 1.8 */}
                  <li>
                    Người Đại Diện: là người đại diện theo pháp luật của Người
                    Sử Dụng, bao gồm cha mẹ, người giám hộ và các trường hợp
                    khác theo quy định của pháp luật.
                  </li>
                  {/* 1.9 */}
                  <li>
                    Dịch Vụ zBusiness (Sau đây có thể gọi là “zBusiness” hoặc
                    “Dịch Vụ”): là một dịch vụ nâng cấp và bổ sung các tính năng
                    giúp người dùng làm việc thuận tiện hơn trên Zalo.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          {/*  ĐIỀU 2: NỘI DUNG DỊCH VỤ */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--second`}
              size="xlg-20"
              weight="bold"
            >
              NỘI DUNG DỊCH VỤ
            </Text>

            <div className={`${owlClass}__terms__term`}>
              <Text>
                zBusiness là Dịch Vụ cho phép Người Sử Dụng nâng cấp và bổ sung
                các tính năng giúp người dùng làm việc thuận tiện hơn trên Zalo.
              </Text>
            </div>
          </div>

          {/*  ĐIỀU 3: CHẤP NHẬN ĐIỀU KHOẢN SỬ DỤNG VÀ SỬA ĐỔI */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--third`}
              size="xlg-20"
              weight="bold"
            >
              CHẤP NHẬN ĐIỀU KHOẢN SỬ DỤNG VÀ SỬA ĐỔI
            </Text>
            <ol
              className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--third`}
            >
              {/* 3.1 */}
              <li>
                Để có thể sử dụng Dịch Vụ zBusiness, Người Sử Dụng sẽ đọc kỹ và
                lựa chọn đồng ý và tuân theo các điều khoản được quy định tại
                Thỏa Thuận này và quy định, quy chế mà zBusiness liên kết, tích
                hợp (nếu có) bao gồm nhưng không giới hạn:
                <ol className={`${owlClass}__terms__term__ol__dash`}>
                  <li>
                    Thỏa Thuận Sử Dụng Dịch Vụ Zalo:{" "}
                    <a
                      target="_blank"
                      href="http://zaloapp.com/zalo/dieukhoan/"
                    >
                      http://zaloapp.com/zalo/dieukhoan/
                    </a>
                    ;
                  </li>
                  <li>
                    Thỏa Thuận Sử Dụng Dịch Vụ zBox:{" "}
                    <a target="_blank" href="https://zbox.vn/service-contract">
                      https://zbox.vn/service-contract
                    </a>
                    ;
                  </li>
                  <li>
                    Quy định về đăng ký và sử dụng tài khoản OA doanh nghiệp:{" "}
                    <a
                      target="_blank"
                      href="https://oa.zalo.me/home/documents/policy/quy-dinh-ve-dang-ky-va-su-dung-tai-khoan-zalo-official-account-doanh-nghiep_3002576019495799852"
                    >
                      https://oa.zalo.me/home/documents/policy/quy-dinh-ve-dang-ky-va-su-dung-tai-khoan-zalo-official-account-doanh-nghiep_3002576019495799852
                    </a>
                    ;
                  </li>
                  <li>
                    Chính Sách Bảo Mật Thông Tin:{" "}
                    <a target="_blank" href="https://zbox.vn/terms-of-privacy">
                      https://zbox.vn/terms-of-privacy
                    </a>
                    ;
                  </li>
                  <li>
                    Chính Sách Thanh Toán:{" "}
                    <a
                      target="_blank"
                      href="https://zbox.vn/service-contract?q=term-of-payment"
                    >
                      https://zbox.vn/service-contract?q=term-of-payment
                    </a>
                    ;
                  </li>
                  <li>
                    Điều Khoản Quy Định Về Khiếu Nại Liên Quan Đến Quyền Sở Hữu
                    Trí Tuệ:{" "}
                    <a target="_blank" href="https://zbox.vn/terms-of-complain">
                      https://zbox.vn/terms-of-complain
                    </a>
                    ;
                  </li>
                  <li>
                    Thỏa Thuận Liên Quan Đến Lệnh Trừng Phạt Kinh Tế Và Chống
                    Rửa Tiền:{" "}
                    <a
                      target="_blank"
                      href="https://vng.com.vn/policy/sanction-aml-representation-warranty-2332/"
                    >
                      https://vng.com.vn/policy/sanction-aml-representation-warranty-2332/
                    </a>
                    .
                  </li>
                </ol>
                <ol className={`${owlClass}__terms__term__ol`}>
                  {/* 3.2 */}
                  <li>
                    VNG bảo lưu quyền được sửa đổi, bổ sung bất kỳ và toàn bộ
                    nội dung của Thỏa Thuận này tại bất kỳ thời điểm nào mà
                    không cần báo trước hay cần có được sự đồng ý trước của
                    Người Sử Dụng.
                  </li>
                  {/* 3.3 */}
                  <li>
                    Trường hợp có bất kỳ xung đột hoặc mâu thuẫn nào giữa các
                    điều khoản và điều kiện tại Thỏa Thuận này với các hợp đồng
                    dịch vụ hay bất kỳ văn bản nào được ký tên, đóng dấu giữa
                    đại diện có thẩm quyền của VNG và Người Sử Dụng, hoặc tổ
                    chức do Người Sử Dụng đại diện thì các quy định tại hợp đồng
                    dịch vụ đó sẽ được ưu tiên áp dụng.
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          {/*  ĐIỀU 4: ĐĂNG KÝ SỬ DỤNG DỊCH VỤ */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--fourth`}
              size="xlg-20"
              weight="bold"
            >
              ĐĂNG KÝ SỬ DỤNG DỊCH VỤ
            </Text>
            <ol
              className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--fourth`}
            >
              {/* 4.1 */}
              <li>
                Người Sử Dụng phải đủ năng lực hành vi dân sự, phải đủ 16 tuổi
                trở lên mới được phép đăng ký Zalo ID và sử dụng Dịch Vụ
                zBusiness.
                <ol className={`${owlClass}__terms__term__ol`}>
                  {/* 4.2 */}
                  <li>
                    Dịch Vụ zBusiness chỉ được cung cấp và sử dụng duy nhất trên
                    ứng dụng Zalo và trên lãnh thổ Việt Nam.
                  </li>
                  {/* 4.3 */}
                  <li>
                    Để làm rõ, trong trường hợp Người Sử Dụng thực hiện đăng ký,
                    sử dụng, thanh toán phí Dịch Vụ nhưng thuộc trường hợp người
                    chưa thành niên (chưa đủ 18 tuổi hoặc một độ tuổi khác tùy
                    theo pháp luật quy định tại thời điểm giao dịch), Người Sử
                    Dụng phải được sự đồng ý của Người Đại Diện nếu sự đồng ý
                    này là bắt buộc theo quy định của pháp luật dân sự có liên
                    quan.
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          {/*  ĐIỀU 5. SỬ DỤNG DỊCH VỤ TÍNH PHÍ */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              size="xlg-20"
              weight="bold"
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--fifth`}
            >
              SỬ DỤNG DỊCH VỤ TÍNH PHÍ
            </Text>

            <div className={`${owlClass}__terms__term`}>
              <Text>
                zBusiness cung cấp các gói Dịch Vụ tính phí (“Gói zBusiness”)
                cho phép Người Sử Dụng tiếp cận các tính năng ưu đãi vượt trội.
              </Text>
              <ol
                className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--fifth`}
              >
                {/* 5.1 */}
                <li>
                  <Text
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Phí và thanh toán
                  </Text>
                  <ol className={`${owlClass}__terms__term__ol__alpha`}>
                    <li>
                      Phí Dịch Vụ được quy định tại giao điện đăng ký Gói
                      zBusiness tương ứng.
                    </li>
                    <li>
                      Dịch Vụ mà Người Sử Dụng yêu cầu sẽ được khởi tạo sau khi
                      Người Sử Dụng hoàn thành nghĩa vụ thanh toán, số tiền
                      Người Sử Dụng thanh toán trước cho VNG sẽ không được hoàn
                      lại trong bất kỳ trường hợp nào.
                    </li>
                    <li>
                      Để làm rõ, trong trường hợp Người Sử Dụng thuộc trường hợp
                      người chưa thành niên, việc thanh toán phí Dịch Vụ theo
                      quy định tại Điều này có thể được thực hiện bởi (i) Người
                      Đại Diện của Người Sử Dụng, hoặc (ii) Người Sử Dụng, nhưng
                      trong mọi trường hợp Người Sử Dụng phải được sự đồng ý của
                      Người Đại Diện nếu sự đồng ý này là bắt buộc theo quy định
                      của pháp luật dân sự có liên quan.
                    </li>
                  </ol>
                  <ol className={`${owlClass}__terms__term__ol`}>
                    {/* 5.2 */}
                    <li>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Hủy
                      </Text>
                      <ol className={`${owlClass}__terms__term__ol__alpha`}>
                        <li>
                          Phí Dịch Vụ không được hoàn lại trong bất kỳ trường
                          hợp nào, nếu Người Sử Dụng hủy đăng ký Gói zBusiness
                          thì thời hạn sử dụng của Gói zBusiness đó sẽ được duy
                          trì cho đến hết số ngày Người Sử Dụng được sử dụng.
                        </li>
                        <li>
                          Để dừng tính năng tự động gia hạn, Người Sử Dụng phải
                          hủy đăng ký Gói zBusiness ít nhất 24 giờ trước khi hết
                          hạn sử dụng.
                        </li>
                      </ol>
                    </li>
                    {/* 5.3 */}
                    <li>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Tự động gia hạn
                      </Text>
                      <ol className={`${owlClass}__terms__term__ol__alpha`}>
                        <li>
                          Đối với Gói zBusiness được áp dụng tính năng tự động
                          gia hạn, trong vòng 24 giờ trước khi hết thời hạn sử
                          dụng, tính năng này tự kích hoạt thêm một (1) chu kỳ
                          sử dụng nữa bằng với thời hạn đang áp dụng của Gói
                          zBusiness đó và ghi nợ một khoản thanh toán tương ứng
                          vào phương tiện thanh toán của Người Sử Dụng.
                        </li>
                        <li>
                          VNG sẽ tự động và định kỳ tính phí theo gói mà Người
                          Sử Dụng đã chọn, bao gồm mọi khoản thuế hiện hành, vào
                          phương thức thanh toán liên kết với tài khoản. VNG
                          cũng có thể yêu cầu phê duyệt trước cho một khoản tiền
                          tối đa bằng giá trị của giao dịch và gửi tin nhắn Zalo
                          của Người Sử Dụng để nhắc nhở về việc thanh toán và
                          trao đổi các thông tin khác liên quan đến tài khoản có
                          gói đăng ký Dịch Vụ.
                        </li>
                        <li>
                          Người Sử Dụng có trách nhiệm thanh toán tất cả các
                          khoản Phí Dịch Vụ đúng hạn và cung cấp cho VNG thông
                          tin chi tiết về tài khoản ví điện tử hoặc thẻ tín dụng
                          hợp lệ để thanh toán tất cả các khoản phí đó. Nếu
                          không thể tính phí thành công vào thẻ tín dụng hoặc ví
                          điện tử của Người Sử Dụng đối với các khoản phí đến
                          hạn, VNG có quyền hủy bỏ hoặc hạn chế quyền sử dụng
                          Dịch Vụ.
                        </li>
                        <li>
                          Nếu Người Sử Dụng muốn chỉ định tài khoản ví điện tử
                          hoặc thẻ tín dụng khác hoặc nếu tình trạng thẻ tín
                          dụng hoặc ví điện tử của Người Sử Dụng có thay đổi,
                          Người Sử Dụng phải thay đổi thông tin trực tuyến trong
                          mục Thông tin tài khoản; Người Sử Dụng có thể tạm thời
                          không truy cập được vào Dịch Vụ trong khi VNG xác minh
                          thông tin thanh toán mới. Chúng tôi có thể liên hệ với
                          Người Sử Dụng qua email về các vấn đề liên quan đến
                          tài khoản.
                        </li>
                        <li>
                          Việc áp dụng tự động gia hạn được thông báo tại giao
                          điện đăng ký Gói zBusiness tương ứng.
                        </li>
                      </ol>
                    </li>
                    {/* 5.4 */}
                    <li>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Thay đổi:
                      </Text>{" "}
                      <Text>
                        Các thay đổi (nếu có) về mức phí, các đặc tính của Gói
                        zBusiness sẽ theo quyết định của VNG và sẽ được thông
                        báo công khai trên website, ứng dụng. Phí Dịch Vụ sau
                        khi thay đổi sẽ tự động áp dụng trong các lần gia hạn kế
                        tiếp trong trường hợp Người Sử Dụng đồng ý tiếp tục sử
                        dụng Dịch Vụ theo các quy định tại Thỏa Thuận này.
                      </Text>
                    </li>
                    {/* 5.5 */}
                    <li>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Hóa đơn:
                      </Text>{" "}
                      <Text>
                        Trường hợp cần xuất hóa đơn, Người Sử Dụng phải gửi yêu
                        cầu tại thời điểm trong ngày thanh toán hoặc kích hoạt
                        Dịch Vụ. VNG sẽ xuất hóa đơn tài chính hợp lệ theo đúng
                        quy định pháp luật, theo các thông tin mà Người Sử Dụng
                        cung cấp, và sau tối đa 31 (ba mươi mốt) ngày kể từ ngày
                        VNG nhận được yêu cầu.
                      </Text>
                    </li>
                    {/* 5.6 */}
                    <li>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Chính sách bảo mật thanh toán
                      </Text>{" "}
                      <Text>
                        được quy định tại{" "}
                        <a
                          href="https://zbox.vn/terms-of-privacy"
                          target="_blank"
                        >
                          https://zbox.vn/terms-of-privacy
                        </a>
                      </Text>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>

          {/*  ĐIỀU 6: TRÁCH NHIỆM CỦA NGƯỜI SỬ DỤNG */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--sixth`}
              size="xlg-20"
              weight="bold"
            >
              TRÁCH NHIỆM CỦA NGƯỜI SỬ DỤNG
            </Text>
            <ol
              className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--sixth`}
            >
              {/* 6.1	*/}
              <li>
                Trong quá trình sử dụng sản phẩm, nếu Người Sử Dụng vi phạm bất
                cứ điều khoản nào trong quy chế sử dụng Zalo ID và Thỏa thuận
                này hoặc thỏa thuận sử dụng sản phẩm khác của VNG được quy định
                trên website, ứng dụng; VNG có toàn quyền chấm dứt, xóa bỏ tài
                khoản Zalo và Dịch Vụ của Người Sử Dụng mà không cần sự đồng ý
                của Người Sử Dụng và không phải chịu bất cứ trách nhiệm nào đối
                với Người Sử Dụng.
                <ol className={`${owlClass}__terms__term__ol`}>
                  {/* 6.2	*/}
                  <li>
                    Người Sử Dụng có trách nhiệm tự quản trị và bảo mật thông
                    tin tài khoản Zalo ID của Người Sử Dụng để sử dụng Dịch Vụ
                    và chịu trách nhiệm về mọi hành vi được thực hiện bởi tài
                    khoản này.
                  </li>
                  {/* 6.3	*/}
                  <li>
                    Người Sử Dụng cam đoan và đảm bảo rằng, Người Sử Dụng sẽ chỉ
                    sử dụng Gói zBusiness cho các hoạt động kinh doanh và/hoặc
                    các hoạt động hợp pháp khác của chính Người Sử Dụng; và để
                    thực hiện, triển khai các hoạt động này, Người Sử Dụng đã có
                    đầy đủ Giấy phép cũng như sự phê chuẩn cần thiết từ Cơ quan
                    Nhà nước có thẩm quyền theo đúng quy định pháp luật Việt Nam
                    (nếu có).
                  </li>
                  {/* 6.4	*/}
                  <li>
                    Người Sử Dụng cam kết và đảm bảo rằng, Người Sử Dụng sẽ
                    không sử dụng Gói zBusiness để thực hiện bất kỳ hành vi vi
                    phạm pháp luật nào. Trường hợp Người Sử Dụng vi phạm thì VNG
                    có quyền ngay lập tức ngừng cung ứng Dịch Vụ cho Người Sử
                    Dụng, khóa tài khoản, đóng băng toàn bộ dữ liệu, thông tin
                    của Người Sử Dụng đang chứa đựng tại VNG và chuyển cho Cơ
                    quan Nhà nước có thẩm quyền để xử lý theo quy định của pháp
                    luật; toàn bộ phí Dịch Vụ mà Người Sử Dụng đã thanh toán
                    trước cho VNG sẽ không được hoàn lại dưới bất kỳ hình thức
                    nào.
                  </li>
                  {/* 6.5	*/}
                  <li>
                    Mọi hành vi lợi dụng việc sử dụng tài khoản Zalo, đăng ký sử
                    dụng Gói zBusiness nhằm thực hiện hoặc thực hiện các hành vi
                    vi phạm pháp luật được cho là hành vi cấm, Người Sử Dụng
                    không được phép thực hiện.
                  </li>
                  {/* 6.6 */}
                  <li>
                    Khi nhận được thông tin về khiếu nại, phản ánh từ Bên Thứ Ba
                    về việc Người Sử Dụng vi phạm trong quá trình sử dụng Gói
                    dịch vụ zBusiness, bao gồm nhưng không giới hạn từ cơ quan
                    nhà nước có thẩm quyền, VNG được quyền ngay lập tức tạm
                    ngưng Dịch Vụ của Người Sử Dụng cho đến khi sự việc được
                    giải quyết.{" "}
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          {/*  ĐIỀU 7: GIỚI HẠN TRÁCH NHIỆM  */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--seventh`}
              size="xlg-20"
              weight="bold"
            >
              GIỚI HẠN TRÁCH NHIỆM
            </Text>
            <ol
              className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--seventh`}
            >
              {/* 7.1	*/}
              <li>
                VNG sẽ áp dụng các biện pháp kỹ thuật, an ninh, bảo mật cần
                thiết theo quy định của pháp luật và theo các tuyên bố, cam kết
                chất lượng của VNG để bảo mật thông tin cá nhân của Người Sử
                Dụng. Mặc dù vậy, các rủi ro liên quan đến việc cung cấp, bảo
                mật dữ liệu cá nhân, cho dù là cung cấp trực tiếp, qua điện
                thoại hay qua mạng internet hay qua các phương tiện kỹ thuật sẽ
                luôn tiềm ẩn và không có hệ thống kỹ thuật hay biện pháp an
                ninh, bảo mật nào là an toàn tuyệt đối hay có thể chống lại được
                tất cả các “hacker”, “tamper” (những người xâm nhập trái phép để
                lục lọi thông tin); do đó, trong trường hợp thông tin cá nhân
                của Người Sử Dụng bị lộ do bị hack hoặc các nguyên nhân khác nằm
                ngoài tầm kiểm soát của VNG thì Người Sử Dụng theo đây đồng ý
                miễn trừ cho VNG toàn bộ trách nhiệm liên quan.
                <ol className={`${owlClass}__terms__term__ol`}>
                  {/* 7.2	*/}
                  <li>
                    VNG hoàn toàn không chịu trách nhiệm rủi ro về mọi giao dịch
                    của bạn với bên thứ ba trong quá trình sử dụng Dịch Vụ của
                    VNG.
                  </li>
                  {/* 7.3	*/}
                  <li>
                    VNG có thể thêm những tính năng mới, hoặc xóa bỏ những tính
                    năng hiện tại đối với gói Dịch Vụ vì lý do về pháp luật hoặc
                    lý do kĩ thuật. Trong tương lai, một số tính năng có thể sẽ
                    trở thành tính năng miễn phí đối với tất cả Người Sử Dụng.
                  </li>
                  {/* 7.4	*/}
                  <li>
                    VNG luôn luôn cố gắng để Người Sử Dụng có thể truy cập và sử
                    dụng các tính năng trong điều kiện tốt nhất. Tuy nhiên,
                    trong một vài trường hợp, có thể xảy ra những lỗi kĩ thuật
                    khiến VNG tạm thời không thể cung cấp Dịch Vụ của mình, bao
                    gồm cả Dịch Vụ zBusiness. VNG sẽ không đền bù bất kì chi phí
                    hậu quả trực tiếp hoặc gián tiếp nào do việc tạm ngưng Dịch
                    Vụ do những lỗi kĩ thuật này.
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          {/*  ĐIỀU 8. THỜI HẠN VÀ CHẤM DỨT THOẢ THUẬN */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              size="xlg-20"
              weight="bold"
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--eighth`}
            >
              THỜI HẠN VÀ CHẤM DỨT THOẢ THUẬN
            </Text>

            <div className={`${owlClass}__terms__term`}>
              <ol
                className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--eighth`}
              >
                {/* 8.1 */}
                <li>
                  <Text
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Thời Hạn:
                  </Text>
                  <ol className={`${owlClass}__terms__term__ol__alpha`}>
                    <li>
                      Thỏa Thuận này có thời hạn theo quy định tại thời hạn sử
                      dụng Gói zBusiness mà Người Sử Dụng lựa chọn.
                    </li>
                    <li>
                      Thỏa Thuận này sẽ được chấm dứt trước thời hạn khi xảy ra
                      một trong các trường hợp sau:
                      <ul
                        className={`${owlClass}__terms__term__ol__alpha__circle`}
                      >
                        <li>
                          Người Sử Dụng và VNG thỏa thuận chấm dứt Thỏa Thuận
                          trước thời hạn;
                        </li>
                        <li>
                          Người Sử Dụng hoặc VNG buộc phải giải thể, phá sản
                          theo quyết định của Cơ quan Nhà nước có thẩm quyền;
                        </li>
                        <li>
                          Người Sử Dụng thực hiện bất kỳ hành vi vi phạm pháp
                          luật nào;
                        </li>
                        <li>
                          Một bên vi phạm nghĩa vụ, cam kết, cam đoan, bảo đảm
                          trong Thỏa Thuận này mà không khắc phục trong vòng năm
                          (5) Ngày Làm Việc kể từ ngày nhận được thông báo/yêu
                          cầu của bên còn lại.
                        </li>
                        <li>
                          Người Sử Dụng vi phạm quy định, thỏa thuận liên quan
                          đến lệnh trừng phạt kinh tế và chống rửa tiền.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Trong trường hợp Người Sử Dụng chủ động chấm dứt sử dụng
                      Dịch Vụ trước thời gian thỏa thuận, hoặc Dịch Vụ bị chấm
                      dứt do hành vi phạm của Người Sử Dụng thì toàn bộ giá trị
                      Người Sử Dụng đã thanh toán cho VNG sẽ không được hoàn trả
                      lại.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>

          {/*  ĐIỀU 9: XỬ LÝ DỮ LIỆU KHI CHẤM DỨT DỊCH VỤ  */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--ninth`}
              size="xlg-20"
              weight="bold"
            >
              XỬ LÝ DỮ LIỆU KHI CHẤM DỨT DỊCH VỤ
            </Text>
            <ol
              className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--ninth`}
            >
              {/* 9.1	*/}
              <li>
                Người Sử Dụng có nghĩa vụ tải về, sao lưu, gỡ bỏ, xóa toàn bộ dữ
                liệu của Người Sử Dụng ra khỏi hệ thống, Dịch Vụ của VNG trong
                vòng 30 ngày sau khi chấm dứt sử dụng Gói zBusiness vì bất kỳ
                nguyên nhân nào.
                <ol className={`${owlClass}__terms__term__ol`}>
                  {/* 9.2	*/}
                  <li>
                    Người Sử Dụng hiểu rõ và đồng ý rằng, VNG có quyền (nhưng
                    không có nghĩa vụ) xóa toàn bộ dữ liệu của Người Sử Dụng
                    trên hệ thống, Dịch Vụ của VNG sau 30 ngày kể từ khi chấm
                    dứt Dịch Vụ với Người Sử Dụng.
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          {/*  ĐIỀU 10. GIẢI QUYẾT KHIẾU NẠI, TỐ CÁO, BỒI THƯỜNG VÀ THỎA THUẬN TRỌNG TÀI */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              size="xlg-20"
              weight="bold"
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--tenth`}
            >
              GIẢI QUYẾT KHIẾU NẠI, TỐ CÁO, BỒI THƯỜNG VÀ THỎA THUẬN TRỌNG TÀI
            </Text>

            <div className={`${owlClass}__terms__term`}>
              <ol
                className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--tenth`}
              >
                {/* 10.1 */}
                <li>
                  <Text
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Nguyên tắc xử lý vi phạm:
                  </Text>{" "}
                  <Text>
                    Người Sử Dụng vi phạm Thỏa Thuận này thì tùy theo mức độ
                    nghiêm trọng của hành vi vi phạm, zBusiness sẽ đơn phương và
                    toàn quyền quyết định hình thức xử lý phù hợp dưới đây:
                  </Text>
                  <ol className={`${owlClass}__terms__term__ol__alpha`}>
                    <li>
                      Khóa tài khoản Zalo ID có thời hạn hoặc vĩnh viễn, điều đó
                      đồng nghĩa với việc Dịch Vụ zBusiness sẽ bị khóa.
                    </li>
                    <li>
                      Hủy bỏ toàn bộ những quyền lợi của Người Sử Dụng gắn liền
                      với Dịch Vụ zBusiness.
                    </li>
                  </ol>
                  <ol className={`${owlClass}__terms__term__ol`}>
                    {/* 10.2	*/}
                    <li>
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Giải quyết tranh chấp, khiếu nại
                      </Text>
                      <ol className={`${owlClass}__terms__term__ol__alpha`}>
                        {/* a. */}
                        <li>
                          Bất kỳ tranh chấp phát sinh trong quá trình sử dụng
                          của sử dụng zBusiness sẽ được giải quyết theo pháp
                          luật hiện hành của nước Cộng hòa xã hội chủ nghĩa Việt
                          Nam.
                        </li>
                        {/* b. */}
                        <li>
                          Bất kỳ khiếu nại nào phát sinh trong quá sử dụng
                          zBusiness phải được gửi đến VNG ngay sau khi xảy ra sự
                          kiện phát sinh khiếu nại.
                          <ul
                            className={`${owlClass}__terms__term__ol__alpha__circle`}
                          >
                            <li>Địa chỉ liên lạc: Bộ Phận zBusiness</li>
                            <li>
                              Địa chỉ: Z06 Đường số 13, Phường Tân Thuận Đông,
                              Quận 7, TP. HCM
                            </li>
                            <li>
                              Email:{" "}
                              <a href="mailto:hotro@zalo.me">hotro@zalo.me</a>
                            </li>
                            <li>
                              Zalo OA:{" "}
                              <a
                                href="https://zalo.me/2840376786067208467"
                                target="_blank"
                              >
                                {" "}
                                https://zalo.me/2840376786067208467
                              </a>
                            </li>
                            <li>
                              Người Sử Dụng vi phạm quy định, thỏa thuận liên
                              quan đến lệnh trừng phạt kinh tế và chống rửa
                              tiền.
                            </li>
                          </ul>
                        </li>
                        {/* c. */}
                        <li>
                          VNG sẽ căn cứ từng trường hợp cụ thể để có phương án
                          giải quyết cho phù hợp. Khi thực hiện quyền khiếu nại,
                          người khiếu nại có nghĩa vụ cung cấp các giấy tờ, bằng
                          chứng, căn cứ có liên quan đến việc khiếu nại và phải
                          chịu trách nhiệm về nội dung khiếu nại, giấy tờ, bằng
                          chứng, cắn cứ do mình cung cấp theo quy định pháp
                          luật.
                        </li>
                        {/* d. */}
                        <li>
                          VNG chỉ hỗ trợ, giải quyết khiếu nại, tố cáo của Người
                          Sử Dụng trong trường hợp bạn đã ghi đầy đủ, trung thực
                          và chính xác thông tin khi đăng ký tài khoản.
                        </li>
                        {/* e. */}
                        <li>
                          Người Sử Dụng đồng ý bảo vệ, bồi hoàn và loại trừ VNG
                          khỏi những nghĩa vụ pháp lý, tố tụng, tổn thất, chi
                          phí bao gồm nhưng không giới hạn án phí, chi phí luật
                          sư, chuyên gia tư vấn có liên quan đến việc giải quyết
                          hoặc phát sinh từ sự vi phạm của Người Sử Dụng trong
                          quá trình sử dụng zBusiness.
                        </li>
                        {/* f. */}
                        <li>
                          Mọi tranh chấp liên quan tới việc sử dụng zBusiness
                          giữa Người Sử Dụng và VNG sẽ được giải quyết bằng
                          trọng tài tại Trung tâm Trọng tài Quốc tế Việt Nam
                          (VIAC) theo Quy tắc tố tụng trọng tài của Trung tâm
                          này và các điều kiện sau:
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>

          {/*  ĐIỀU 11: BẢN QUYỀN  */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--eleventh`}
              size="xlg-20"
              weight="bold"
            >
              BẢN QUYỀN
            </Text>
            <ol
              className={`${owlClass}__terms__term__ol ${owlClass}__terms__term__ol--eleventh`}
            >
              {/* 11.1	*/}
              <li>
                Tất cả quyền Sở Hữu Trí Tuệ của hoặc liên quan đến zBusiness
                thuộc quyền sở hữu hoặc được cấp phép hợp pháp cho VNG sử dụng,
                khai thác.
                <ol className={`${owlClass}__terms__term__ol`}>
                  {/* 11.2	*/}
                  <li>
                    Trừ khi được sự đồng ý của VNG, Người Sử Dụng không được
                    phép sử dụng, sao chép, xuất bản, tái sản xuất, truyền hoặc
                    phân phát bằng bất cứ hình thức nào, bất cứ thành phần nào
                    các quyền Sở Hữu Trí Tuệ đối với zBusiness, bao gồm nhưng
                    không giới hạn các bản ghi được đăng tải, sử dụng trên Dịch
                    Vụ zBusiness.
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          {/*  ĐIỀU 12. HIỆU LỰC CỦA THỎA THUẬN */}
          <div className={`${owlClass}__terms__term`}>
            <Text
              size="xlg-20"
              weight="bold"
              className={`${owlClass}__terms__term__title ${owlClass}__terms__term__title--twelfth`}
            >
              HIỆU LỰC CỦA THỎA THUẬN
            </Text>

            <div className={`${owlClass}__terms__term`}>
              <Text>
                Thỏa thuận này có hiệu lực kể từ ngày Người Sử Dụng đăng ký Gói
                zBusiness. Trong trường hợp một hoặc một số điều khoản Thỏa
                Thuận này xung đột với các quy định của luật pháp Việt Nam, điều
                khoản đó sẽ được chỉnh sửa cho phù hợp với quy định pháp luật
                hiện hành, phần còn lại của Thỏa Thuận vẫn giữ nguyên giá trị.
              </Text>
            </div>
          </div>

          <Text center weight="bold" size="xlg-22">
            HẾT
          </Text>
        </div>
      </div>
    </Layout>
  );
};

export default ServiceAgreement;
