import type { IconProps } from "./model";

const MinusIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "10"}
    height={size || "2"}
    viewBox="0 0 10 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55228 0 10 0.447715 10 1C10 1.55228 9.55229 2 9 2H1C0.447715 2 0 1.55228 0 1Z"
      fill="white"
    />
  </svg>
);

export default MinusIcon;
