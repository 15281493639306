import React from "react";

import "./style.scss";
import Image from "components/image";
import Text from "components/text";
import { useNavigate } from "react-router-dom";

interface FooterProps {}

const owlClass = "footer";

const Footer: React.FC<FooterProps> = () => {
  const navigate = useNavigate();
  return (
    <div className={owlClass}>
      <div className={`${owlClass}__container`}>
        <div className={`${owlClass}__container__left`}>
          <Image
            srcImage="https://res-zalo.zadn.vn/upload/media/2024/2/6/footer_zBusiness_1707187525600_162047.svg"
            width={194.208}
            height={32}
            loading="lazy"
          />
          <Text color="white">Copyright © 2024 zBusiness</Text>
        </div>
        <div className={`${owlClass}__container__right`}>
          <div className={`${owlClass}__container__right__top`}>
            <div className={`${owlClass}__container__right__top__information`}>
              <Text color="white">Công Ty Cổ Phần VNG</Text>
              <Text color="white">
                Địa chỉ: Z06 Đường số 13, Phường Tân Thuận Đông, Quận 7, TPHCM
              </Text>
              <Text color="white">
                GPDKKD: 0303490096 do sở KH & ĐT TP.HCM cấp ngày 9/9/2004.
              </Text>
            </div>
            <Image
              width={134}
              height={50}
              loading="lazy"
              srcImage="https://res-zalo.zadn.vn/upload/media/2023/11/1/ministry_of_industry_trade_1698825306017_781919.svg"
              onClick={() =>
                window.open(
                  "http://online.gov.vn/Home/WebDetails/110501"
                )
              }
            />
          </div>
          <div className={`${owlClass}__container__right__bottom`}>
            <Text color="white">
              Khi sử dụng dịch vụ, bạn cũng đồng ý với{" "}
              <Text
                color="white"
                underline
                cursor
                onClick={() => navigate("/service-agreement")}
              >
                Thoả thuận sử dụng
              </Text>{" "}
              dịch vụ zBusiness.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
