import React from "react";
import { useNavigate } from "react-router-dom";

// store
import { onScrollTo } from "store/home";

import Image from "components/image";

// utils
import { useAppDispatch } from "hooks";

import "./style.scss";

interface LogoZbusinessProps {
  onClick?: () => void;
}

const owlClass = "logo-zBusiness";

const LogoZbusiness: React.FC<LogoZbusinessProps> = ({
  onClick,
}: LogoZbusinessProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goHome = () => {
    if (onClick) {
      onClick();
    } else {
      dispatch(onScrollTo("homepage-logo"));
      navigate("/");
    }
  };

  return (
    <div className={owlClass}>
      <Image
        srcImage="https://res-zalo.zadn.vn/upload/media/2024/2/5/zBusiness_1707139503598_3353.svg"
        onClick={goHome}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default LogoZbusiness;
