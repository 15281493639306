import type { IconProps } from "./model";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChevronRight = ({ size, color = "#3D3D3D", ...props }: IconProps) => (
  <svg
    width={size || "17"}
    height={size || "16"}
    viewBox="0 0 17 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.14645 2.64645C6.34171 2.45118 6.65829 2.45118 6.85355 2.64645L11.8536 7.64645C12.0488 7.84171 12.0488 8.15829 11.8536 8.35355L6.85355 13.3536C6.65829 13.5488 6.34171 13.5488 6.14645 13.3536C5.95118 13.1583 5.95118 12.8417 6.14645 12.6464L10.7929 8L6.14645 3.35355C5.95118 3.15829 5.95118 2.84171 6.14645 2.64645Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRight;
