import React from "react";

import Text from "components/text";
import Title from "components/title";

import "./style.scss";

interface SolutionInfoProps {
  topDescription: string;
  title: string;
  btmDescription: string;
}

const owlClass = "solution-info";

const SolutionInfo: React.FC<SolutionInfoProps> = ({
  topDescription,
  title,
  btmDescription,
}) => (
  <div className={owlClass}>
    <Text size="xlg-18">{topDescription}</Text>
    <div className={`${owlClass}__title`}>
      <Title size="h3">{title}</Title>
    </div>
    <Text size="md" color="cod-grey">
      {btmDescription}
    </Text>
  </div>
);

export default SolutionInfo;
