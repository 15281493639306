export const removeNullValueObj = (nullValueObj: Record<string, any>) => {
    const ret: Record<string, string> = {};
    for (const key in nullValueObj) {
      const value = nullValueObj[key];
      if (value !== null && value !== undefined) {
        ret[key] = value;
      }
    }
    return ret;
}
