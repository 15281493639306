import { createSlice } from "@reduxjs/toolkit";

import { RequestErrorTypes } from "constant/request-errors";

import { CODE } from "services/http-common";
import home from "services/home";
import type { SubscriptionI } from "z-types/subscription";
import { Z_BUSINESS, Z_BUSINESS_LITE } from "constant/home";

interface initialSubscriptionStateI {
  subscription: SubscriptionI | null;
  isLoadingSubscription: boolean;
  errorSubscription: RequestErrorTypes | boolean;
}

const initialState: initialSubscriptionStateI = {
  subscription: null,
  isLoadingSubscription: true,
  errorSubscription: false,
};

// const RESTORE_PRODUCTID = "zap-z85595t8"

const findProductInSubscription = (
  subscription: SubscriptionI,
  duration: 1 | 12,
  name: "basic" | "pro"
) => {
  if (!subscription || !subscription[name] || !subscription[name].products) {
    return null;
  }
  return subscription[name].products.find(
    (product) => product.duration === duration
  );
};

// Slice
const slice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    onFetching: (state) => {
      state.isLoadingSubscription = true;
    },
    fetchedSuccess: (
      state,
      action: {
        payload: SubscriptionI | null;
      }
    ) => {
      state.subscription = action.payload;
      const productProAnnual = findProductInSubscription(
        action.payload as SubscriptionI,
        12,
        "pro"
      );
      if (productProAnnual) {
        for (let i = 0; i < Z_BUSINESS.packages.length; i++) {
          const products = Z_BUSINESS.packages[i].products.filter(
            (product) => product.duration === productProAnnual.duration
          );
          if (products) {
            for (let j = 0; j < products.length; ++j) {
              products[j].hidden = products[j].productId !== productProAnnual.productId;
            }
          }
        }
        Z_BUSINESS_LITE.forEach((product) => {
          if (product.packageId === "pro-12-month") {
            product.hidden = product.productId !== productProAnnual.productId;
          }
        });
      }
      state.isLoadingSubscription = false;
      state.errorSubscription = false;
    },
    fetchedError: (
      state,
      action: {
        payload: RequestErrorTypes | boolean;
      }
    ) => {
      state.isLoadingSubscription = false;
      state.errorSubscription = action.payload;
    },

    resetSubscription: (state) => {
      state.errorSubscription = true;
      state.subscription = null;
      // console.log('reset subscription')
      //
      // for (let i = 0; i < Z_BUSINESS.packages.length; i++) {
      //   const products = Z_BUSINESS.packages[i].products.filter(
      //     (product) => product.duration === 12
      //   );
      //   if (products) {
      //     for (let j = 0; j < products.length; ++j) {
      //       products[j].hidden = products[j].productId !== RESTORE_PRODUCTID;
      //     }
      //   }
      // }
      // Z_BUSINESS_LITE.forEach((product) => {
      //   if (product.packageId === "pro-12-month") {
      //     product.hidden = product.productId !== RESTORE_PRODUCTID;
      //   }
      // });
    },
  },
});

// Actions
const { onFetching, fetchedSuccess, fetchedError, resetSubscription } =
  slice.actions;

export const onFetchSubscription =
  (userId: string) =>
  async (dispatch: any): Promise<void> => {
    dispatch(onFetching());

    const subs = await home.getSubscription({ userId: userId });
    if (subs) {
      if (!subs.data || subs.error_code !== CODE.SUCCESS) {
        dispatch(fetchedError(true));
      } else {
        dispatch(fetchedSuccess(subs.data));
      }
    } else {
      dispatch(fetchedError(true));
    }
  };

export const onResetSubscription =
  () =>
  async (dispatch: any): Promise<void> => {
    try {
      dispatch(resetSubscription());
    } catch (error) {
      // Ignored
    }
  };

export default slice.reducer;
