import React, { useCallback, useState } from "react";

import Text from "components/text";
import BannerTrial from "components/banner-trial";
import Title from "components/title";

import "./style.scss";
import home from "services/home";
import { ChevronDownIcon, ChevronUpIcon } from "icons";

interface FAQSProps {}

const owlClass = "faqs";

const FAQS_LIST = [
  {
    key: 0,
    question: "zBusiness (Zalo Business) là gì?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            zBusiness (Zalo Business) được hiểu là tài khoản Zalo cá nhân với
            các tính năng được nâng cấp và bổ sung giúp người dùng kinh doanh
            một cách hiệu quả. Với zBusiness, người dùng có thể tiếp cận, chăm
            sóc khách hàng thuận tiện, dễ dàng và chuyên nghiệp ngay trên Zalo.
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 1,
    question: "zBusiness phí là bao nhiêu?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            zBusiness (Zalo Business) cung cấp các gói dịch vụ với ưu điểm và
            chi phí duy trì khác nhau.{"\n"}
            Basic: miễn phí trọn đời.{"\n"}
            Pro: mức phí từ 5.500 đồng/ngày.{"\n"}
            Bạn có thể xem chi tiết giá gói và quyền lợi đi kèm tại{" "}
            <a
              href="#explore-homepage"
              style={{ color: "white" }}
              target="_blank"
            >
              đây
            </a>
            .
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 2,
    question: "Cách nâng cấp lên tài khoản zBusiness?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Các bước đăng ký tài khoản zBusiness (Zalo Business):
            <br />
            Bước 1: Truy cập vào đường link:{" "}
            <a href="/" style={{ color: "white" }} target="_blank">
              https://business.zbox.vn/
            </a>
            .
            <br />
            Bước 2: Chọn mục Giá gói để xem các gói dịch vụ zBusiness.
            <br />
            Bước 3: Chọn đăng ký phân loại gói phù hợp với nhu cầu.
            <br />
            Bước 4: Chọn thời gian gói theo tháng hoặc năm (nếu đăng ký gói
            Pro).
            <br />
            Bước 5: Tiến hành thanh toán theo hướng dẫn trên màn hình.
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 3,
    question: "zBusiness hoạt động trên những phiên bản nào?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Zalo hỗ trợ các tính năng của gói Business trong phiên bản mới nhất.
            Cụ thể:
            <ul
              style={{
                marginLeft: "20px",
                padding: "0",
                listStyleType: "disc",
                marginTop: "0",
                display: "flex",
                gap: "0",
              }}
            >
              <li className="content">
                Zalo PC từ phiên bản 22.06.01 trở lên.
              </li>
              <li className="content">
                Zalo Mobile từ phiên bản 22.10.02 trở lên.
              </li>
            </ul>
            Nếu bạn đang dùng Zalo PC hoặc Zalo mobile phiên bản cũ hơn những
            phiên bản kể trên, vui lòng cập nhật app để sử dụng được gói
            zBusiness.
          </Text>
        </li>
      </ul>
    ),
  },

  {
    key: 4,
    question:
      "Khi nâng cấp lên tài khoản zBusiness, các dữ liệu của tôi có bị mất hay không?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Khi nâng cấp lên 1 trong các gói tài khoản zBusiness, bạn sẽ có thêm
            với những tính năng tương ứng của gói đó, thông tin và dữ liệu truy
            cập của bạn trước đó không bị ảnh hưởng.
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 5,
    question:
      "Tôi có thể xem lại giao dịch đã thực hiện, xuất hóa đơn và quản lý gói zBusiness như thế nào?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Bạn có thể xem lại giao dịch, xuất hóa đơn và quản lý dịch vụ tại{" "}
            <a href="https://zbox.vn" style={{ color: "white" }}>
              https://zbox.vn
            </a>{" "}
            <br />
            Tại mục{" "}
            <a
              href="https://zbox.vn/account?tab=lich-su-mua-hang"
              style={{ color: "white" }}
            >
              Quản lý tài khoản.
            </a>
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 6,
    question:
      "Tôi có thể đăng ký mua 1 gói tính năng cho nhiều tài khoản khác nhau không?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Rất tiếc hiện tại Zalo chưa hỗ trợ đa tài khoản. Bạn có thể mua gói
            tính năng cho từng tài khoản độc lập, giá của các gói là giá dành
            cho 1 tài khoản.
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 7,
    question:
      "Tại sao tôi đang sử dụng zBusiness nhưng một số tính năng bị hạn chế hoặc không hoạt động?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Trong quá trình sử dụng dịch vụ zBusiness, nếu bạn có những hoạt
            động bất thường mà Zalo nhận định là vi phạm{" "}
            <a
              style={{ color: "white" }}
              href="https://help.zalo.me/huong-dan/chuyen-muc/chinh-sach-cong-dong-zalo/"
            >
              Chính sách cộng đồng Zalo
            </a>
            , chúng tôi sẽ đình chỉ tài khoản hoặc hạn chế một số tính năng.
            Trường hợp bạn không sử dụng Zalo trong 45 ngày, tài khoản sẽ bị xóa
            (theo quy định của Zalo). Ở tất cả các trường hợp trên, zBusiness sẽ
            không hoàn tiền và đền bù với phần chi phí bạn đã thanh toán.
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 8,
    question:
      "Sẽ như thế nào nếu gói hết hạn, hủy gói hoặc xuống gói có quyền lợi thấp hơn?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Nếu gói hết hạn, bạn sẽ ngay lập tức trở về thành người dùng thường
            với các giới hạn về quyền lợi của người dùng thường. <br />
            Nếu bạn hủy gói, bạn sẽ sử dụng gói dịch vụ đến khi hết hạn và trở
            về thành người dùng thường. Hiện tại zBusiness chưa hỗ trợ chuyển
            xuống gói có quyền lợi thấp hơn, bạn có thể chờ gói hết hạn và đăng
            ký lại gói thấp hơn.
          </Text>
        </li>
      </ul>
    ),
  },
  {
    key: 9,
    question: "Nếu gặp vấn đề, tôi liên hệ ai để hỗ trợ?",
    answer: (
      <ul>
        <li>
          <Text color="white" size="md">
            Bạn có thể liên hệ hỗ trợ qua:{" "}
            <a
              href="https://forms.office.com/pages/responsepage.aspx?id=bioRfOIQCU6vxC43vGDYIWFEBo90YTFCncB_r1ngbc1URTBZRUs3VzdVNlpSNlVCRzZJVFZSRTdKSi4u"
              style={{ color: "white" }}
            >
              Link
            </a>
            {"\n"}
            Hoặc chat với chúng tôi qua Zalo OA: zBusiness
          </Text>
        </li>
      </ul>
    ),
  },
];

const FAQS: React.FC<FAQSProps> = () => {
  const [activatedKey, setActivatedKey] = useState<number>(-1);

  const onOpenQuestion = useCallback(
    (key: number) => {
      const contentEl = document.getElementsByClassName(
        `${owlClass}__container__list__faq__answer__content`
      );
      const answerEl = document.getElementsByClassName(
        `${owlClass}__container__list__faq__answer`
      );

      if (key === activatedKey) {
        answerEl[key].setAttribute("style", `height: ${0}px`);
        setActivatedKey(-1);
        return;
      }

      if (activatedKey > -1) {
        answerEl[activatedKey].setAttribute("style", `height: ${0}px`);
      }

      const height = contentEl[key].clientHeight;
      answerEl[key].setAttribute("style", `height: ${height}px`);

      setActivatedKey(key);
    },
    [activatedKey]
  );

  return (
    <div className={owlClass}>
      <div className={`${owlClass}__container`}>
        <BannerTrial />
        <Title size="h3" color="white" center>
          Câu hỏi thường gặp
        </Title>
        <div className={`${owlClass}__container__list`}>
          {FAQS_LIST.map(({ key, question, answer }) => (
            <div key={key} className={`${owlClass}__container__list__faq`}>
              <div
                key={key}
                className={`${owlClass}__container__list__faq__question`}
                onClick={() => {
                  home.trackingLog({
                    flow: "FAQ",
                    action: "click_faq",
                    params: JSON.stringify({ key: key }),
                  });
                  onOpenQuestion(key);
                }}
              >
                <Title size="h4" color="white">
                  {question}
                </Title>
                {activatedKey === key ? <ChevronUpIcon size={24}/> : <ChevronDownIcon size={24}/>}
              </div>
              <div className={`${owlClass}__container__list__faq__answer`}>
                <div
                  className={`${owlClass}__container__list__faq__answer__content`}
                >
                  {answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQS;
