import React, { useCallback, useEffect, useMemo, useRef } from "react";

// store
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { onScrollTo } from "store/home";

// components
import Section from "components/section";
import Features from "components/features";
import PricePackages from "components/price-packages";
import Solution from "components/solution";
import FAQS from "components/faqs";
import Button from "components/button";
import Text from "components/text";
import ComparePackages from "components/compare-packages";
import Title from "components/title";

// utils
import useWindowSize from "hooks/use-window-size";
import { DESKTOP_SIZE, SectionTypes } from "constant/home";

import "./style.scss";

import home from "services/home";
import {
  getValueFromSessionStorage,
  setValueToSessionStorage,
} from "utils/local-storage";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

interface HomeProps {}

const owlClass = "home";
const schemaPage = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  url: "https://business.zbox.vn/",
  name: "zBusiness - Kinh doanh hiệu quả trên Zalo",
  description:
    "Đăng ký nâng cấp tài khoản zBusiness (Zalo Business Account) dành cho các cá nhân có nhu cầu sử dụng các tính năng nâng cao phục vụ kinh doanh trên Zalo.",
};

const schemaFAQ = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "zBusiness (Zalo Business) là gì?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "zBusiness (Zalo Business) được hiểu là tài khoản Zalo cá nhân với các tính năng được nâng cấp và bổ sung giúp người dùng kinh doanh một cách hiệu quả. Với zBusiness, người dùng có thể tiếp cận, chăm sóc khách hàng thuận tiện, dễ dàng và chuyên nghiệp ngay trên Zalo.",
      },
    },
    {
      "@type": "Question",
      name: "zBusiness phí là bao nhiêu?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "zBusiness (Zalo Business) cung cấp các gói dịch vụ với ưu điểm và chi phí duy trì khác nhau. Basic: miễn phí trọn đời. Pro: mức phí từ 5.500 đồng/ngày. Bạn có thể xem chi tiết giá gói và quyền lợi đi kèm tại đây.",
      },
    },
    {
      "@type": "Question",
      name: "Cách nâng cấp lên tài khoản zBusiness?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Các bước đăng ký tài khoản zBusiness (Zalo Business): Bước 1: Truy cập vào đường link: https://business.zbox.vn/. Bước 2: Chọn mục Giá gói để xem các gói dịch vụ zBusiness. Bước 3: Chọn đăng ký phân loại gói phù hợp với nhu cầu. Bước 4: Chọn thời gian gói theo tháng hoặc năm (nếu đăng ký gói Pro). Bước 5: Tiến hành thanh toán theo hướng dẫn trên màn hình.",
      },
    },
    {
      "@type": "Question",
      name: "zBusiness hoạt động trên những phiên bản nào?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Zalo hỗ trợ các tính năng của gói Business trong phiên bản mới nhất. Cụ thể: Zalo PC từ phiên bản 22.06.01 trở lên. Zalo Mobile từ phiên bản 22.10.02 trở lên. Nếu bạn đang dùng Zalo PC hoặc Zalo mobile phiên bản cũ hơn những phiên bản kể trên, vui lòng cập nhật app để sử dụng được gói zBusiness.",
      },
    },
    {
      "@type": "Question",
      name: "Khi nâng cấp lên tài khoản zBusiness, các dữ liệu của tôi có bị mất hay không?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Khi nâng cấp lên 1 trong các gói tài khoản zBusiness, bạn sẽ có thêm với những tính năng tương ứng của gói đó, thông tin và dữ liệu truy cập của bạn trước đó không bị ảnh hưởng.",
      },
    },
    {
      "@type": "Question",
      name: "Tôi có thể xem lại giao dịch đã thực hiện, xuất hóa đơn và quản lý gói zBusiness như thế nào?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Bạn có thể xem lại giao dịch, xuất hóa đơn và quản lý dịch vụ tại https://zbox.vn Tại mục Quản lý tài khoản.",
      },
    },
    {
      "@type": "Question",
      name: "Tôi có thể đăng ký mua 1 gói tính năng cho nhiều tài khoản khác nhau không?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Rất tiếc hiện tại Zalo chưa hỗ trợ đa tài khoản. Bạn có thể mua gói tính năng cho từng tài khoản độc lập, giá của các gói là giá dành cho 1 tài khoản.",
      },
    },
    {
      "@type": "Question",
      name: "Tại sao tôi đang sử dụng zBusiness nhưng một số tính năng bị hạn chế hoặc không hoạt động?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Trong quá trình sử dụng dịch vụ zBusiness, nếu bạn có những hoạt động bất thường mà Zalo nhận định là vi phạm Chính sách cộng đồng Zalo, chúng tôi sẽ đình chỉ tài khoản hoặc hạn chế một số tính năng. Trường hợp bạn không sử dụng Zalo trong 45 ngày, tài khoản sẽ bị xóa (theo quy định của Zalo). Ở tất cả các trường hợp trên, zBusiness sẽ không hoàn tiền và đền bù với phần chi phí bạn đã thanh toán.",
      },
    },
    {
      "@type": "Question",
      name: "Sẽ như thế nào nếu gói hết hạn, hủy gói hoặc xuống gói có quyền lợi thấp hơn?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Nếu gói hết hạn, bạn sẽ ngay lập tức trở về thành người dùng thường với các giới hạn về quyền lợi của người dùng thường. Nếu bạn hủy gói, bạn sẽ sử dụng gói dịch vụ đến khi hết hạn và trở về thành người dùng thường. Hiện tại zBusiness chưa hỗ trợ chuyển xuống gói có quyền lợi thấp hơn, bạn có thể chờ gói hết hạn và đăng ký lại gói thấp hơn.",
      },
    },
    {
      "@type": "Question",
      name: "Nếu gặp vấn đề, tôi liên hệ ai để hỗ trợ?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Bạn có thể liên hệ hỗ trợ qua: Link Hoặc chat với chúng tôi qua Zalo OA: zBusiness",
      },
    },
  ],
};
const Home: React.FC<HomeProps> = () => {
  const dispatch = useAppDispatch();

  const { width } = useWindowSize();
  const isDesktop = useMemo(() => width > DESKTOP_SIZE, [width]);

  const solutionSectionRef = useRef<HTMLDivElement | null>(null);
  const pricesSectionRef = useRef<HTMLDivElement | null>(null);
  const comparePackagesSectionRef = useRef<HTMLDivElement | null>(null);
  const faqsSectionRef = useRef<HTMLDivElement | null>(null);

  const { scrollTo } = useAppSelector((state: RootState) => state.home);
  const location = useLocation();

  useEffect(() => {
    document.addEventListener("wheel", trackingWheel);
    document.addEventListener("touchmove", trackingWheel);

    const data = getValueFromSessionStorage("referer");
    if (data == null) {
      setValueToSessionStorage(
        "referer",
        JSON.stringify({
          referer: document.referrer,
          params: home.arrayToObject(
            new URLSearchParams(window.location.search)
          ),
        })
      );
    }

    home.trackingLog({
      flow: "Onboarding",
      action: "visit_business_zbox",
    });

    return () => {
      document.removeEventListener("wheel", trackingWheel);
      document.removeEventListener("touchmove", trackingWheel);
    };
  }, []);

  useEffect(() => {
    const hash = location.hash;
    const section = hash?.replace("#", "");
    if (section) {
      dispatch(onScrollTo(`${section}` as SectionTypes));
      window.history.replaceState({}, document.title, "/" + location.search);
    }
  }, [location.hash]);

  useEffect(() => {
    if (
      scrollTo === "homepage-logo" ||
      scrollTo === "homepage-breadcrumb" ||
      scrollTo === "homepage"
    ) {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    } else if (
      pricesSectionRef &&
      pricesSectionRef.current &&
      (scrollTo === "price-breadcrumb" ||
        scrollTo === "explore-homepage" ||
        scrollTo === "price-url")
    ) {
      scrollToPosition(pricesSectionRef.current);
    } else if (
      solutionSectionRef &&
      solutionSectionRef.current &&
      (scrollTo === "solution-breadcrumb" || scrollTo === "solution-url")
    ) {
      scrollToPosition(solutionSectionRef.current);
    } else if (
      comparePackagesSectionRef &&
      comparePackagesSectionRef.current &&
      scrollTo === "compare-packages"
    ) {
      scrollToPosition(comparePackagesSectionRef.current);
    } else if (
      faqsSectionRef &&
      faqsSectionRef.current &&
      (scrollTo === "faq-breadcrumb" || scrollTo === "faq-url")
    ) {
      scrollToPosition(faqsSectionRef.current);
    }
  }, [scrollTo]);

  const scrollToPosition = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        const y = ref.getBoundingClientRect().top + window.scrollY - 48;
        window.scroll({
          top:
            scrollTo === "price-scroll"
              ? window.screen.width > 1132
                ? y + 105
                : y + 19.9375
              : y,
          behavior: "smooth",
        });
      }
    },
    [scrollTo]
  );

  const trackingWheel = useCallback(() => {
    const solutionSection =
      solutionSectionRef?.current?.getBoundingClientRect().top || 0;

    const pricesScrollY =
      pricesSectionRef?.current?.getBoundingClientRect().top || 0;

    /* const faqsScrollY =
      faqsSectionRef?.current?.getBoundingClientRect().top || 0; */

    /* if (faqsScrollY - 64 < 0) {
      if (scrollTo === "faq-scroll") {
        return;
      }
      dispatch(onScrollTo("faq-scroll"));
    } else */
    if (
      window.innerHeight + Math.round(window.scrollY) >=
      document.body.offsetHeight
    ) {
      if (scrollTo === "faq-scroll") {
        return;
      }
      dispatch(onScrollTo("faq-scroll"));
      return;
    }

    if (solutionSection - 64 < 0) {
      if (scrollTo === "solution-scroll") {
        return;
      }
      dispatch(onScrollTo("solution-scroll"));
    } else if (pricesScrollY - 64 < 0) {
      if (scrollTo === "price-scroll") {
        return;
      }
      dispatch(onScrollTo("price-scroll"));
    } else {
      if (scrollTo === "homepage-scroll") {
        return;
      }
      dispatch(onScrollTo("homepage-scroll"));
    }
  }, [scrollTo]);

  return (
    <>
      <Helmet>
        <title>zBusiness - Kinh doanh hiệu quả trên Zalo</title>
        <meta
          name="description"
          content="Đăng ký nâng cấp tài khoản zBusiness (Zalo Business Account) dành cho các cá nhân có nhu cầu sử dụng các tính năng nâng cao phục vụ kinh doanh trên Zalo."
        />
        <script type="application/ld+json">{JSON.stringify(schemaPage)}</script>
        <script type="application/ld+json">{JSON.stringify(schemaFAQ)}</script>
      </Helmet>
      <Section
        name="introduce"
        topDescription={
          <Text size="xlg-32">Kinh doanh hiệu quả trên Zalo cùng</Text>
        }
        title={<Title size="h1">Tài khoản zBusiness</Title>}
        btmDescription={
          <Text size="xlg-18" color="cod-grey">
            Nâng cấp và bổ sung các tính năng giúp người dùng làm việc thuận
            tiện hơn trên Zalo
          </Text>
        }
      >
        <Button
          type="primary"
          size="x-large"
          onClick={() => {
            home.trackingLog({
              flow: "Onboarding",
              action: "click_button_explore",
            });
            dispatch(onScrollTo("explore-homepage"));
          }}
        >
          Khám phá ngay
        </Button>
      </Section>
      <div className={owlClass}>
        <Section name="features">
          <Features />
        </Section>

        <div ref={pricesSectionRef}>
          <Section
            title={<Title size="h3">Chọn gói phù hợp với bạn</Title>}
            btmDescription={
              <Text size="xlg-18" color="secondary">
                Sau khi đăng ký, bạn có thể hủy gia hạn bất cứ lúc nào
              </Text>
            }
          >
            <PricePackages />
          </Section>
        </div>

        <div ref={solutionSectionRef}>
          <Section
            name="solution"
            title={
              <Title size="h2">
                {isDesktop
                  ? `Nâng cấp kênh giao tiếp Zalo thành
                  công cụ kinh doanh hiệu quả`
                  : "Nâng cấp kênh giao tiếp Zalo thành công cụ kinh doanh hiệu quả​"}
              </Title>
            }
            btmDescription={
              <Text size="xlg-18" color="secondary">
                Giúp bạn và khách hàng tiềm năng có trải nghiệm mượt mà
              </Text>
            }
          >
            <Solution />
          </Section>
        </div>

        <div ref={comparePackagesSectionRef}>
          <Section
            name="compare-packages"
            title={<Title size="h3">So sánh các gói và tính năng</Title>}
            btmDescription={
              <Text size="xlg-18" color="secondary">
                Sau khi đăng ký, bạn có thể hủy gia hạn bất cứ lúc nào
              </Text>
            }
          >
            <ComparePackages />
          </Section>
        </div>

        <div ref={faqsSectionRef}>
          <FAQS />
        </div>
      </div>
    </>
  );
};

export default Home;
