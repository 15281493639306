import React, { useCallback, useEffect, useRef, useState } from 'react';

// hook
import { RootState, store } from 'store';
import { useAppSelector } from 'hooks';

// utils
import { CODE } from 'services/http-common';
import home from 'services/home';
import type { ProductI } from 'z-types/home';
import { ModalType, TRIAL_PRODUCT_ID } from 'constant/home';

import Button from 'components/button';
import Spin from 'components/spin';
import Text from 'components/text';
import Label from 'components/label';
import Image from 'components/image';

import Home from 'services/home';

import './style.scss';
import loginModal from 'utils/login-modal';
import useNotify from 'hooks/useNotify';
import { onFetchSubscription } from 'store/subscription';

interface RegisterPackageProps {
  name: string;
  isTrial?: boolean;
  products?: Array<ProductI>;
  size: 'small' | 'large' | 'medium' | 'semi-large';
  isBlock?: boolean;
  isOpenTrialModalLoggedIn?: boolean;
  errorCodeModalLoggedIn?: string;
  btnText?: string | React.ReactNode;
  product?: { productId: string };
  source: string;
  flow?: string;
}

const packageModalStyle = 'package-modal';

const RegisterPackage: React.FC<RegisterPackageProps> = ({
  isTrial,
  name,
  products,
  size,
  isBlock,
  isOpenTrialModalLoggedIn,
  errorCodeModalLoggedIn,
  btnText,
  product,
  source,
  flow,
}) => {
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const { openModal } = loginModal();
  const { showProductsModal, setShowNotifyModal, setShowProductsModal } =
    useNotify();

  const { subscription, isLoadingSubscription } = useAppSelector(
    (state: RootState) => state.subscription
  );

  const { isLoadingProfile } = useAppSelector(
    (state: RootState) => state.profile
  );

  const [isRequestPayment, setIsRequestPayment] = useState<boolean>(false);
  const isRequestPaymentRef = useRef<boolean>(false);

  const [productSelected, setProductSelected] = useState<ProductI | null>(null);

  const setProductModal = (value: ModalType | null) => {
    setShowProductsModal(value);
  };
  const setNotifyModal = (value: ModalType | null) => {
    setShowNotifyModal(value);
  };

  const logTrackingClickButtonRegister = (name: any) => {
    home.trackingLog({
      flow: 'Payment',
      action: 'click_button_register',
      params: JSON.stringify({ package: name, source }),
    });
  };

  useEffect(() => {
    if (!showProductsModal) {
      setProductSelected(null);
    }
  }, [showProductsModal]);

  useEffect(() => {
    if (productSelected && !isTrial && !product) {
      setProductModal({
        title: name,
        description: (
          <div className={packageModalStyle}>
            <div className={`${packageModalStyle}__selection`}>
              <Text size="xlg-20" weight="semi-bold">
                Chọn gói để nâng cấp
              </Text>
              {products?.map((product: ProductI) => (
                <div
                  key={product.productId}
                  className={
                    productSelected?.productId === product.productId
                      ? `${packageModalStyle}__selection__option ${packageModalStyle}__selection__option--activate`
                      : `${packageModalStyle}__selection__option`
                  }
                  onClick={() => onSelectProduct(product)}
                >
                  <div
                    className={`${packageModalStyle}__selection__option__left`}
                  >
                    <Text
                      color="grey"
                      weight="semi-bold"
                    >{`${product.duration} tháng`}</Text>
                    <div
                      className={`${packageModalStyle}__selection__option__left__price`}
                    >
                      <Text
                        size="xlg-24"
                        weight="semi-bold"
                        color={
                          productSelected?.productId === product.productId
                            ? 'primary'
                            : undefined
                        }
                      >
                        {product.price}
                      </Text>
                      {product.savePercent && (
                        <Label
                          text={product.savePercent}
                          type="sale"
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        />
                      )}
                    </div>
                    {product.promotionText && (
                      <Text color="dove-gray" weight="semi-bold">
                        {product.promotionText}
                      </Text>
                    )}
                  </div>
                  <Image
                    srcImage={
                      productSelected?.productId === product.productId
                        ? 'https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_blue_radio_1698310463932_565835.svg'
                        : 'https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_radio_1698310532882_576586.svg'
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ),
        okBtn: (
          <Button
            type="primary"
            size="large"
            block
            onClick={handleBuyProduct}
            key={'buy_now'}
          >
            {isRequestPaymentRef.current ? <Spin isSmall /> : `Đăng ký ngay`}
          </Button>
        ),
      });
    }
  }, [productSelected, name, products, isTrial, isRequestPayment, product]);

  useEffect(() => {
    if (isOpenTrialModalLoggedIn) {
      setProductModal({
        title: 'Basic',
        description: (
          <div className={packageModalStyle}>
            <div style={{ width: 430 }}>
              <Text
                size="xlg-28"
                weight="semi-bold"
                center
                className={`${packageModalStyle}__basic-title`}
              >
                Bạn muốn kích hoạt gói dùng thử?
              </Text>
              <Text center>
                Sau khi kích hoạt, vui lòng mở ứng dụng Zalo để khám phá những
                tính năng mới của gói zBusiness Basic
              </Text>
            </div>
          </div>
        ),
        okBtn: (
          <Button type="primary" size="large" block onClick={handleBuyTrial}>
            {isRequestPaymentRef.current ? (
              <Spin isSmall />
            ) : (
              'Dùng thử miễn phí'
            )}
          </Button>
        ),
      });
    }
  }, [isOpenTrialModalLoggedIn]);

  useEffect(() => {
    if (errorCodeModalLoggedIn && profile) {
      checkErrorCodePayment();
    }
  }, [errorCodeModalLoggedIn, profile]);

  const closeProductsModal = () => {
    setProductModal(null);
    setProductSelected(null);
  };

  const handleErrorNotification = (errorCode: number) => {
    switch (errorCode) {
      case -2001:
        setNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Nâng cấp gói zBusiness không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn chưa đủ điều kiện để thực hiện việc nâng cấp
                lên gói zBusiness
              </Text>
            </div>
          ),
        });
        break;
      case -2003:
        setNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đăng ký không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn có thể chờ hết hạn gói hiện tại để đăng ký gói này
              </Text>
            </div>
          ),
        });
        break;
      case -2004:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/25/zds_il_user_account_ekyc_warning_1711354288375_317079.png"
                width={220}
                height={220}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Vui lòng thực hiện định danh tài khoản Zalo để tiếp tục
              </Text>
              <Text center size="xlg-18">
                Bạn cần mở ứng dụng Zalo trên thiết bị di động, kiểm tra tin
                nhắn từ <strong>OA Zalo Định Danh Tài Khoản</strong> và làm theo
                hướng dẫn.
              </Text>
            </div>
          ),
        });
        break;
      case -2005:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/25/zds_il_user_account_ekyc_warning_1711354288375_317079.png"
                width={220}
                height={220}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Vui lòng thực hiện lại định danh tài khoản Zalo để tiếp tục
              </Text>
              <Text center size="xlg-18">
                Bạn cần mở ứng dụng Zalo trên thiết bị di động, kiểm tra tin
                nhắn từ <strong>OA Zalo Định Danh Tài Khoản</strong> và làm theo
                hướng dẫn.
              </Text>
            </div>
          ),
        });
        break;
      case -2008:
        setNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Nâng cấp gói zBusiness không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn đang sử dụng dịch vụ zStyle nên chưa thể nâng
                cấp zBusiness. Chúng tôi sẽ hỗ trợ trong thời gian sớm nhất.
              </Text>
            </div>
          ),
        });
        break;

      case -2009:
        setNotifyModal({
          description: (
            <div className="notify-modal">
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đăng ký không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn không nằm trong danh sách giới hạn được trải nghiệm chương
                trình
              </Text>
            </div>
          ),
        });
        break;
      case -2010:
        setNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói dùng thử zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;
      case -2013:
        setNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói ưu đãi zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;
      default:
        setNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
        break;
    }
  };

  const checkErrorCodePayment = useCallback(async () => {
    if (!errorCodeModalLoggedIn) return;
    const checkErrorCodePaymentResp = await home.checkErrorCodePayment({
      params: errorCodeModalLoggedIn,
      userId: profile?.userId || '',
    });

    if (checkErrorCodePaymentResp.error_code === 0) {
      handleErrorNotification(checkErrorCodePaymentResp.data.errorCode);
    }
  }, [errorCodeModalLoggedIn, profile]);

  const handleBuyTrial = useCallback(async () => {
    if (isRequestPaymentRef?.current) return;

    isRequestPaymentRef.current = true;
    setIsRequestPayment(true);
    Home.trackingLog({
      flow: flow || 'Onboarding',
      action: 'click_to_activate_trial',
      params: JSON.stringify({ source }),
    });
    try {
      const requestPaymentResp = await home.requestTrialPayment({
        userId: profile?.userId || '',
        productId: TRIAL_PRODUCT_ID,
      });

      setProductModal(null);

      if (requestPaymentResp?.error_code !== CODE.SUCCESS) {
        if (
          requestPaymentResp?.error_code === -200 &&
          requestPaymentResp.data
        ) {
          isRequestPaymentRef.current = false;
          setIsRequestPayment(false);
          onLogin(requestPaymentResp.data);

          return;
        }

        Home.trackingLog({
          flow: flow || 'Onboarding',
          action: 'click_to_active_trial_fail',
          params: JSON.stringify({
            fail_reason: `${requestPaymentResp?.error_code}`,
            source,
          }),
        });

        handleErrorNotification(requestPaymentResp?.error_code);

        isRequestPaymentRef.current = false;
        setIsRequestPayment(false);
        return;
      }

      Home.trackingLog({
        flow: flow || 'Onboarding',
        action: 'click_to_active_trial_success',
        params: JSON.stringify({
          source,
        }),
      });

      setNotifyModal({
        title: 'Thông báo',
        description: 'Bạn đã đăng ký dịch vụ zBusiness Basic thành công',
      });
      if (profile) {
        store.dispatch(onFetchSubscription(profile.userId));
      }

      isRequestPaymentRef.current = false;
      setIsRequestPayment(false);
    } catch (error) {
      Home.trackingLog({
        flow: flow || 'Onboarding',
        action: 'click_to_active_trial_fail',
        params: JSON.stringify({ fail_reason: `catch ${error}`, source }),
      });

      setProductModal(null);
      setNotifyModal({
        description: (
          <div className="notify-modal">
            <Image
              srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
              width={160}
              height={160}
            />
            <Text
              center
              size="xlg-24"
              className="notify-modal__title"
              weight="three-quarters-bold"
            >
              Đã có lỗi xảy ra!!!
            </Text>
            <Text center size="xlg-18">
              Vui lòng thử lại.
            </Text>
          </div>
        ),
      });

      isRequestPaymentRef.current = false;
      setIsRequestPayment(false);
    }
  }, [profile, isRequestPayment]);

  const handleBuyProduct = useCallback(async () => {
    if (isRequestPaymentRef?.current) return;

    isRequestPaymentRef.current = true;
    setIsRequestPayment(true);

    if (!product && !productSelected) return;

    const { productId } = product || productSelected!;

    Home.trackingLog({
      flow: 'Payment',
      action: 'click_button_choose_package',
      params: JSON.stringify({
        product_id: productId,
        type: 'subscription',
        source,
      }),
    });

    try {
      const requestPaymentResp = await home.requestProductPayment({
        productId,
        userId: profile?.userId || '',
      });

      closeProductsModal();

      if (requestPaymentResp?.error_code !== CODE.SUCCESS) {
        isRequestPaymentRef.current = false;
        setIsRequestPayment(false);

        if (
          requestPaymentResp?.error_code === -200 &&
          requestPaymentResp.data
        ) {
          onLogin(requestPaymentResp.data);
          return;
        }

        Home.trackingLog({
          flow: 'Payment',
          action: 'click_button_choose_package_fail',
          params: JSON.stringify({
            fail_reason: `${requestPaymentResp?.error_code}`,
            type: 'subscription',
            source,
          }),
        });

        handleErrorNotification(requestPaymentResp?.error_code);
        return;
      }

      isRequestPaymentRef.current = false;
      setIsRequestPayment(false);

      window.location.href = requestPaymentResp.data;
    } catch (error) {
      closeProductsModal();
      Home.trackingLog({
        flow: 'Payment',
        action: 'click_button_choose_package_fail',
        params: JSON.stringify({
          fail_reason: `unknown_error`,
          error,
          type: 'subscription',
          source,
        }),
      });
      setShowNotifyModal({
        description: (
          <div className="notify-modal">
            <Image
              srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
              width={160}
              height={160}
            />
            <Text
              center
              size="xlg-24"
              className="notify-modal__title"
              weight="three-quarters-bold"
            >
              Đã có lỗi xảy ra!!!
            </Text>
            <Text center size="xlg-18">
              Vui lòng thử lại.
            </Text>
          </div>
        ),
      });
      isRequestPaymentRef.current = false;
      setIsRequestPayment(false);
    }
  }, [productSelected, profile, isRequestPayment, product]);

  const onSelectProduct = useCallback(
    (product: ProductI) => {
      if (productSelected?.productId !== product.productId) {
        setProductSelected(product);
      }
    },
    [productSelected]
  );

  const onLogin = (redirect: string): void => {
    openModal({
      title: 'Đăng nhập để tiếp tục',
      description: 'Vui lòng đăng nhập tài khoản Zalo của bạn để tiếp tục.',
      okText: 'Đăng nhập',
      onOk: async function () {
        const params = {
          from: 'choose_package',
          source,
        };
        Home.trackingLog({
          flow: flow || 'Onboarding',
          action: 'click_button_sign_in',
          params: JSON.stringify(params),
        });

        window.location.href = redirect
          ? `${import.meta.env.VITE_ZBOX_LOGIN}?redirect=${redirect}`
          : import.meta.env.VITE_ZBOX_LOGIN!;
      },
    });
  };

  const onOpenModalPackage = useCallback(() => {
    if (product) {
      if (isTrial) {
        handleBuyTrial();
      } else {
        handleBuyProduct();
      }
    } else {
      if (isTrial) {
        Home.trackingLog({
          flow: flow || 'Onboarding',
          action: 'click_button_trial',
          params: JSON.stringify({ source }),
        });
        setProductModal({
          title: name,
          description: (
            <div className={packageModalStyle}>
              <div style={{ width: 430 }}>
                <Text
                  size="xlg-28"
                  weight="semi-bold"
                  center
                  className={`${packageModalStyle}__basic-title`}
                >
                  Bạn muốn kích hoạt gói dùng thử?
                </Text>
                <Text center>
                  Sau khi kích hoạt, vui lòng mở ứng dụng Zalo để khám phá những
                  tính năng mới của gói zBusiness Basic
                </Text>
              </div>
            </div>
          ),
          okBtn: (
            <Button
              type="primary"
              size="large"
              block
              onClick={handleBuyTrial}
              key={'ok_btn'}
            >
              {isRequestPaymentRef.current ? (
                <Spin isSmall />
              ) : (
                'Dùng thử miễn phí'
              )}
            </Button>
          ),
        });
        return;
      }
      logTrackingClickButtonRegister(name);

      if (!productSelected && products) {
        setProductSelected(products[0]);
      }
      if (!products?.length) {
        return;
      }
    }
  }, [
    isTrial,
    name,
    profile,
    packageModalStyle,
    products,
    productSelected,
    subscription,
    product,
  ]);

  const findPackageInSubscription = () => {
    if (!subscription) return null;
    if (isTrial) {
      return subscription?.basic;
    }
    if (name === 'Standard') {
      return subscription?.standard;
    } else if (name === 'Pro') {
      return subscription?.pro;
    } else if (name === 'Trial') {
      return subscription?.trial;
    }
    return null;
  };

  if (isLoadingProfile || (isLoadingSubscription && profile?.userId)) {
    return (
      <Button block={isBlock} size={size} disabled type="disable">
        {/* <Spin isSmall /> */}
        {btnText || 'Đang tải...'}
      </Button>
    );
  }

  const buildRegisterButtonUI = () => {
    const packageInSubs = findPackageInSubscription();
    if (profile?.userId && packageInSubs && packageInSubs.products) {
      if (product) {
        const currentProd = packageInSubs.products.find(
          (prod) => prod.productId === product.productId
        );
        if (packageInSubs.state !== 0) {
          return (
            <Button
              block={isBlock}
              type={currentProd?.canBuy ? 'primary' : 'disable'}
              size={size}
              disabled={!currentProd?.canBuy}
              onClick={onOpenModalPackage}
            >
              {currentProd?.canBuy ? btnText || 'Đăng ký gói' : 'Gói đang dùng'}
            </Button>
          );
        }
      } else {
        if (packageInSubs.state >= packageInSubs.products.length) {
          return (
            <Button
              block={isBlock}
              type={packageInSubs.canOpen ? 'primary' : 'disable'}
              size={size}
              disabled={!packageInSubs.canOpen}
              onClick={onOpenModalPackage}
            >
              {packageInSubs.canOpen ? 'Đăng ký gói' : 'Gói đang dùng'}
            </Button>
          );
        }
        const recommendProduct = packageInSubs.products[packageInSubs.state];
        if (packageInSubs.state !== 0 && recommendProduct) {
          return (
            <Button
              block={isBlock}
              type={'primary'}
              size={size}
              onClick={onOpenModalPackage}
            >
              {`Đổi sang gói ${
                recommendProduct.duration === 12
                  ? '1 năm'
                  : `${recommendProduct.duration} tháng`
              }`}
            </Button>
          );
        }
      }
    }
    return (
      <Button
        block={isBlock}
        type={isTrial ? '' : 'primary'}
        size={size}
        onClick={onOpenModalPackage}
      >
        {isTrial ? btnText || 'Dùng thử miễn phí' : btnText || 'Đăng ký gói'}
      </Button>
    );
  };

  const registerButtonUI = buildRegisterButtonUI();

  return <>{registerButtonUI}</>;
};

export default RegisterPackage;
