import React, { useEffect, useState } from "react";

// store

// components
import Footer from "../footer";

import "./style.scss";

interface LayoutProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const owlClass = "layout";

const Layout: React.FC<LayoutProps> = ({ header, children, footer }) => {
  const [isScrollTop, setIsScrollTop] = useState<boolean>(true);
  useEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset === 0) {
        setIsScrollTop(true);
      } else {
        setIsScrollTop(false);
      }
    };
  }, []);
  return (
    <div className={owlClass}>
      {header && (
        <div
          className={
            isScrollTop
              ? `${owlClass}__header`
              : `${owlClass}__header ${owlClass}__header--scroll`
          }
        >
          {header}
        </div>
      )}
      <div className={`${owlClass}__container`}>{children}</div>
      {footer && <Footer />}
    </div>
  );
};

export default Layout;
