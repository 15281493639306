import React, { useMemo } from "react";

import "./style.scss";

interface TitleProps {
  color?: "white" | "primary";
  size?: "h1" | "h2" | "h3" | "h4";
  center?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const owlClass = "title";

const Title: React.FC<TitleProps> = ({
  size,
  center,
  color,
  style,
  children,
}) => {
  const styleClass = useMemo(() => {
    let style = owlClass;
    if (color) {
      style = style + ` ${owlClass}--${color}`;
    }
    if (size) {
      style = style + ` ${owlClass}--${size}`;
    }
    if (center) {
      style = style + ` ${owlClass}--center`;
    }

    return style;
  }, [color, size, center]);
  return (
    <>
      {(() => {
        switch (size) {
          case "h1":
            return (
              <h1 style={style} className={styleClass}>
                {children}
              </h1>
            );
          case "h2":
            return (
              <h2 style={style} className={styleClass}>
                {children}
              </h2>
            );
          case "h3":
            return (
              <h3 style={style} className={styleClass}>
                {children}
              </h3>
            );
          case "h4":
            return (
              <h4 style={style} className={styleClass}>
                {children}
              </h4>
            );
          default:
            return (
              <h1 style={style} className={styleClass}>
                {children}
              </h1>
            );
        }
      })()}
    </>
  );
};

export default Title;
