import React, { useMemo } from "react";

import Text from "components/text";

import "./style.scss";

interface LabelProps {
  type?: "basic" | "sale" | "hot" | "warm";
  text: string;
  className?: string;
  style?: React.CSSProperties;
}

const owlClass = "label";

const Label: React.FC<LabelProps> = ({ style, type, text, className }) => {
  const styleClass = useMemo(() => {
    let style = owlClass;
    if (type) {
      style = style + ` ${owlClass}--${type}`;
    }

    if (className) {
      style = style + ` ${className}`;
    }

    return style;
  }, [type, className]);

  return (
    <Text style={style} className={styleClass}>
      {text}
    </Text>
  );
};

export default Label;
