import ButtonGroup from "components/button-group/button-group";
import Layout from "components/layout";
import Section from "components/section";
import React, { useEffect, useRef } from "react";

import "./style.scss";
import PricePackagesLite from "components/price-packages-lite";
import { PC_AGENT, Z_BUSINESS_LITE } from "constant/home";
import { RootState, store } from "store";
import { onFetchProfile } from "store/profile";
import { DownArrow } from "icons";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { onScrollTo } from "store/home";
import Text from "components/text";
import home from "services/home";
import { Helmet } from "react-helmet";
import {
  getValueFromSessionStorage,
  setValueToSessionStorage,
} from "utils/local-storage";
import Spin from "components/spin";

const owlClass = "paywall";
const source = "paywall_two_package";

const Paywall: React.FC = () => {
  const [selectedPkg, setSelectedPkg] = React.useState<string>("pro-12-month");
  const { profile, isLoadingProfile } = useAppSelector((state: RootState) => state.profile);
  const initLogin = useRef<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { subscription } = useAppSelector(
    (state: RootState) => state.subscription
  );

  const handleNavigate = (path: string, func?: () => void) => {
    navigate(path);
    func?.();
  };

  const onClickBtnGroup = (activePkg: string) => {
    setSelectedPkg(activePkg);
    home.trackingLog({
      flow: "Onboard Paywall",
      action: "switch_package_pro",
      params: JSON.stringify({ package: activePkg, source }),
    });
  };

  const onClickMoreInfo = () => {
    home.trackingLog({
      flow: "Onboard Paywall",
      action: "click_more_info",
      params: JSON.stringify({ source }),
    });
    handleNavigate("/", () => {
      dispatch(onScrollTo("compare-packages"));
    });
  };

  const onClickServiceAgreement = () => {
    home.trackingLog({
      flow: "Onboard Paywall",
      action: "click_service_agreement",
      params: JSON.stringify({ source }),
    });
    handleNavigate("/service-agreement");
  };

  useEffect(() => {
    if (!initLogin.current) {
      const data = getValueFromSessionStorage("referer");
      if (data == null) {
        setValueToSessionStorage(
          "referer",
          JSON.stringify({
            referer: document.referrer,
            params: home.arrayToObject(
              new URLSearchParams(window.location.search)
            ),
          })
        );
      }
      home.trackingLog({
        flow: "Onboard Paywall",
        action: "visit_paywall_business",
        params: JSON.stringify({ source }),
      });
      store.dispatch(
        onFetchProfile(`${import.meta.env.VITE_DOMAIN}/nang-cap-business`, {
          flow: "Onboard Paywall",
          action: "sign_in_success",
          params: JSON.stringify({ source }),
        })
      );
      initLogin.current = true;
    }
  }, []);

  const visibleProduct = Z_BUSINESS_LITE.filter((pkg) => !pkg.hidden);

  useEffect(() => {
    if (subscription) {
      setSelectedPkg(
        visibleProduct.find((pkg) => pkg.packageId === "pro-12-month")
          ?.packageId || "pro-12-month"
      );
    }
  }, [subscription]);

  if ((isLoadingProfile || !profile?.userId) && window.navigator.userAgent.includes(PC_AGENT)) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div
          style={{
            position: "fixed",
            width: "100vh",
            height: "100vh",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <Section
          name="paywall"
          title={
            <Text size="xlg-28" weight="bold">
              Kinh doanh hiệu quả trên Zalo cùng <br />
              tài khoản zBusiness
            </Text>
          }
        >
          <ButtonGroup
            buttons={visibleProduct
              .filter((pkg) => pkg.isRecommend)
              .map((pkg) => {
                return {
                  index: pkg.selBtn?.index || 0,
                  text: pkg.selBtn?.text || "",
                  onClick: () => {
                    onClickBtnGroup(pkg.packageId);
                  },
                  badge: pkg.selBtn?.badge,
                };
              })}
          />
        </Section>
        <div className={owlClass}>
          <Section style={{ padding: 0, width: "100%" }} name="compact">
            <PricePackagesLite
              productSelected={selectedPkg}
              source={source}
              products={visibleProduct}
            />
          </Section>
          <span className={`${owlClass}_more-info`} onClick={onClickMoreInfo}>
            <span>Xem chi tiết tính năng</span>
            <DownArrow />
          </span>
          <div className={`${owlClass}_service-agreement`}>
            Khi sử dụng dịch vụ, bạn cũng đồng ý với{" "}
            <span onClick={onClickServiceAgreement}>Thoả thuận sử dụng</span>{" "}
            dịch vụ zBusiness.
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Paywall;
