import Footer from "components/footer";
import Header from "components/header";
import Layout from "components/layout";
import { MenuIcon } from "icons";
import React from "react";
import { Outlet } from "react-router-dom";

const Main: React.FC = () => {
  return (
    <Layout
      header={<Header rightIcon={<MenuIcon style={{ cursor: "pointer" }} />} />}
      footer={<Footer />}
    >
      <Outlet />
    </Layout>
  );
};

export default Main;
