import type { IconProps } from "./model";

const AddIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size ||"10"}
    height={size ||"10"}
    viewBox="0 0 10 10"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9C4 9.55229 4.44772 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4V9Z"
      fill="white"
    />
  </svg>
);

export default AddIcon;
