interface Modal {
  title: string;
  description: string;
  okText: string;
  onOk: () => void;
}

const loginModal = () => {
  let isRequestingOkBtn: boolean = false;

  function onCloseModal(el: HTMLDivElement) {
    el.remove();
    isRequestingOkBtn = false;
  }

  function openModal({ title, description, okText, onOk }: Modal) {
    const pLibModal = document.createElement("div");
    pLibModal.setAttribute("id", "p-lib-modal");
    pLibModal.setAttribute("class", "p-lib-modal");
    document.body.appendChild(pLibModal);

    const modalEl = document.getElementById("p-lib-modal");

    const modalContent = document.createElement("div");
    modalContent.setAttribute("class", "p-lib-modal-content");
    pLibModal.appendChild(modalContent);

    // DETECT CLICK OUTSIDE
    if (modalEl) {
      modalEl.onclick = function (e) {
        if (e.target === modalEl) {
          onCloseModal(pLibModal);
        }
      };
    }

    // HEADER
    const modalHeader = document.createElement("div");
    modalHeader.setAttribute("class", "p-lib-modal-header");
    modalHeader.innerHTML = title;
    modalContent.appendChild(modalHeader);

    const closeImg = document.createElement("img");
    closeImg.setAttribute("class", "p-lib-modal-close-icon");
    closeImg.src = "https://p-stc.zdn.vn/jslib/utils/icon-close.svg";
    modalHeader.appendChild(closeImg);

    // DETECT CLICK CLOSE MODAL BY ICON
    closeImg.addEventListener("click", function () {
      onCloseModal(pLibModal);
    });

    // BODY
    const modalBody = document.createElement("div");
    modalBody.setAttribute("class", "p-lib-modal-body");
    modalContent.appendChild(modalBody);

    const modalBodyContent = document.createElement("p");
    modalBodyContent.innerHTML = description;
    modalBody.appendChild(modalBodyContent);

    // FOOTER
    const modalFooter = document.createElement("div");
    modalFooter.setAttribute("class", "p-lib-modal-footer");
    modalContent.appendChild(modalFooter);

    const modalFooterCloseBtn = document.createElement("div");
    modalFooterCloseBtn.setAttribute("class", "p-lib-btn");
    modalFooterCloseBtn.innerHTML = "Đóng";
    modalFooter.appendChild(modalFooterCloseBtn);
    // DETECT CLICK CLOSE MODAL BY BUTTON
    modalFooterCloseBtn.addEventListener("click", function () {
      onCloseModal(pLibModal);
    });

    const modalFooterOkBtn = document.createElement("div");
    modalFooterOkBtn.setAttribute("class", "p-lib-btn primary-btn");
    modalFooterOkBtn.innerHTML = okText;
    modalFooter.appendChild(modalFooterOkBtn);
    // DETECT CLICK OK MODAL BY BUTTON
    modalFooterOkBtn.addEventListener("click", async function () {
      if (isRequestingOkBtn) return;
      isRequestingOkBtn = true;
      modalFooterOkBtn.innerHTML = "Đang tải";
      onCloseModal(pLibModal);
      await onOk();
    });
    // ESC
    document.addEventListener("keydown", function (e) {
      if (e.keyCode === 27) {
        if (modalEl) {
          onCloseModal(pLibModal);
        }
      }
    });
  }

  return {
    openModal,
  };
};
export default loginModal;
