import React, { useCallback, useState } from "react";
import { createPortal } from "react-dom";

// store
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState } from "store";
import { onScrollTo } from "store/home";

// components
import Text from "components/text";

// utils
import { BREADCRUMB, SectionTypes } from "constant/home";

import "./style.scss";
import Button from "components/button";
import home from "services/home";
import { useLocation, useNavigate } from "react-router-dom";
import LogoZBusiness from "components/logo-zbusiness";
import { CloseIcon } from "icons";

interface NavigationMenuProps {
  children: React.ReactNode;
}

const owlClass = "navigation-menu";

const NavigationMenu: React.FC<NavigationMenuProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { scrollTo } = useAppSelector((state: RootState) => state.home);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onOpen = (e?: any) => {
    e?.stopPropagation();
    setIsShow(true);
    setIsOpen(true);
  };

  const onClose = useCallback((e?: any) => {
    e.stopPropagation();
    setIsOpen(false);
  }, []);

  const hideRender = () => {
    setIsShow(false);
  };

  const onRedeemCode = useCallback(() => {
    home.trackingLog({
      flow: "Onboarding",
      action: "click_button_redeem_code",
    });
    window.location.href = import.meta.env.VITE_ZBOX_DOMAIN + "/activate-code";
  }, []);

  return (
    <>
      <div onClick={onOpen}>{children}</div>
      {isShow &&
        createPortal(
          <div
            className={`${owlClass} ${
              isOpen ? `${owlClass}--open` : `${owlClass}--close`
            }`}
            onAnimationEnd={() => (!isOpen ? hideRender() : undefined)}
          >
            <div className={`${owlClass}__title`}>
              <div className={`${owlClass}__left`}>
                <LogoZBusiness />
              </div>
              <div className={`${owlClass}__right`}>
                <CloseIcon
                  size={20}
                  className={`${owlClass}__title__icon`}
                  onClick={(e: any) => onClose(e)}
                />
              </div>
            </div>
            <div className={`${owlClass}__body`}>
              <>
                {BREADCRUMB.map(({ label, navigate: nav }) => (
                  <Text
                    color={(() => {
                      if (location.pathname !== "/") return;
                      switch (nav) {
                        case "homepage-breadcrumb":
                          return scrollTo === "homepage-logo" ||
                            scrollTo === "homepage-breadcrumb" ||
                            scrollTo === "homepage" ||
                            scrollTo === "homepage-scroll"
                            ? "primary"
                            : undefined;

                        case "solution-breadcrumb":
                          return scrollTo === "solution-breadcrumb" ||
                            scrollTo === "solution-scroll" ||
                            scrollTo === "solution-url"
                            ? "primary"
                            : undefined;

                        case "price-breadcrumb":
                          return scrollTo === "price-breadcrumb" ||
                            scrollTo === "price-scroll" ||
                            scrollTo === "price-url" ||
                            scrollTo === "explore-homepage" ||
                            scrollTo === "explore-faq"
                            ? "primary"
                            : undefined;

                        case "faq-breadcrumb":
                          return scrollTo === "faq-breadcrumb" ||
                            scrollTo === "faq-scroll" ||
                            scrollTo === "faq-url"
                            ? "primary"
                            : undefined;

                        default:
                          break;
                      }
                    })()}
                    key={nav}
                    onClick={(e) => {
                      if (location.pathname !== "/") {
                        navigate("/");
                      }
                      dispatch(onScrollTo(nav as SectionTypes));
                      onClose(e);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {label}
                  </Text>
                ))}
                <Text
                  color={
                    location.pathname === "/mua-code" ? "primary" : undefined
                  }
                  onClick={(e) => {
                    navigate("/mua-code");
                    onClose(e);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Mua code
                </Text>
              </>
              <Button
                type="secondary"
                block
                size="semi-large"
                onClick={onRedeemCode}
              >
                Kích hoạt code
              </Button>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default NavigationMenu;
