// utils
import './style.scss';

// components
import Text from 'components/text';
import { ConsultantSellIcon, ExtendContactIcon, TrustingIcon } from 'icons';
import React from 'react';

interface FeaturesProps {}

const owlClass = 'features';

const features = [
  {
    imgSrc: <ExtendContactIcon />,
    title: `Mở rộng danh bạ và tiếp cận khách hàng tiềm năng`,
    description: `Gia tăng cơ hội tiếp cận khách
    hàng để kinh doanh hiệu quả`,
  },
  {
    imgSrc: <ConsultantSellIcon />,
    title: `Tư vấn và bán hàng thuận tiện, dễ dàng`,
    description: `Tạo trải nghiệm tư vấn bán hàng
    thân thiện, nhanh chóng`,
  },
  {
    imgSrc: <TrustingIcon />,
    title: `Xây dựng niềm tin với khách hàng`,
    description: `Sở hữu nhãn nhận diện nổi bật 
    so với đối thủ`,
  },
];

const Features: React.FC<FeaturesProps> = () => (
  <div className={owlClass}>
    {features.map(({ imgSrc, title, description }) => (
      <div className={`${owlClass}__feature`} key={title}>
        <div className={`${owlClass}__feature__icon`}>{imgSrc}</div>
        <div className={`${owlClass}__feature__title`}>
          <Text size='xlg-24' weight='semi-bold'>
            {title}
          </Text>
        </div>
        <Text color='cod-grey'>{description}</Text>
      </div>
    ))}
  </div>
);

export default Features;
