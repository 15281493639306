import type { IconProps } from "./model";

const DownArrow = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "16"}
    height={size || "16"}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3536 6.14645C13.5488 6.34171 13.5488 6.65829 13.3536 6.85355L8.35355 11.8536C8.15829 12.0488 7.84171 12.0488 7.64645 11.8536L2.64645 6.85355C2.45118 6.65829 2.45118 6.34171 2.64645 6.14645C2.84171 5.95118 3.15829 5.95118 3.35355 6.14645L8 10.7929L12.6464 6.14645C12.8417 5.95118 13.1583 5.95118 13.3536 6.14645Z"
      fill="#0D0D0D"
    />
  </svg>
);

export default DownArrow;
