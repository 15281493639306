import type { IconProps } from "./model.js";

const ChevronUpIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "32"}
    height={size || "32"}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8985 19.2318C23.5861 19.5442 23.0795 19.5442 22.7671 19.2318L15.9995 12.4642L9.23183 19.2318C8.91941 19.5442 8.41288 19.5442 8.10046 19.2318C7.78804 18.9194 7.78804 18.4129 8.10046 18.1005L15.4338 10.7671C15.7462 10.4547 16.2527 10.4547 16.5652 10.7671L23.8985 18.1005C24.2109 18.4129 24.2109 18.9194 23.8985 19.2318Z"
      fill="white"
    />
  </svg>
);

export default ChevronUpIcon;
