import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import routes from "./routes";
import { store } from "./store";
import NotifyProvider from "context/Notify";
import "./App.scss";
import Main from "pages/main";
import Home from "pages/home";
import CodePage from "pages/code/code";

function App() {
  return (
    <Provider store={store}>
      <NotifyProvider>
        <Router>
          <Routes>
            <Route element={<Main />}>
              <Route key="home" path="/" element={<Home />} />
              <Route key="mua-code" path="/mua-code" element={<CodePage />} />
            </Route>
            {routes.map((route) => (
              <Route {...route} />
            ))}
          </Routes>
        </Router>
      </NotifyProvider>
    </Provider>
  );
}

export default App;
