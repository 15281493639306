import './style.scss';

import Image from 'components/image';
import SolutionInfo from 'components/solution-info';
import React from 'react';

interface SolutionProps {}

const owlClass = 'solution';

const ZIC_ZAC_SOLUTION = [
  {
    key: 'SOLUTION-1',
    left: (
      <Image
        srcImage='https://res-zalo.zadn.vn/upload/media/2024/1/30/extend_contact_1706602699899_434042.png'
        width={550}
        height={440}
        loading='lazy'
        style={{
          maxWidth: '100%',
        }}
      />
    ),
    right: (
      <SolutionInfo
        topDescription='Gia tăng cơ hội tiếp cận khách hàng với'
        title={`Mở rộng danh bạ bạn bè`}
        btmDescription='Không còn nỗi lo danh bạ thiếu chỗ để kết bạn với
        khách hàng tiềm năng.'
      />
    ),
  },

  // {
  //   key: 'SOLUTION-2',
  //   isGrey: true,
  //   isPushToRight: true,
  //   left: (
  //     <SolutionInfo
  //       topDescription='Tạo sự tin tưởng, uy tín với'
  //       title={`Nhãn Business và
  //       nhật ký cá nhân`}
  //       btmDescription='Khách hàng luôn tìm kiếm những địa chỉ uy tín để liên hệ. Nhãn nhận diện tài khoản kinh doanh, kết hợp bài đăng hàng hóa, dịch vụ trên nhật ký cá nhân sẽ giúp bạn nhận được sự tin tưởng từ khách hàng.'
  //     />
  //   ),
  //   right: (
  //     <Image
  //       srcImage='https://res-zalo.zadn.vn/upload/media/2024/10/18/1650x1320_1729240547187_481361.png'
  //       width={550}
  //       height={440}
  //       loading='lazy'
  //       style={{
  //         maxWidth: '100%',
  //       }}
  //     />
  //   ),
  // },

  {
    key: 'SOLUTION-3',
    isGrey: true,
    isPushToRight: true,
    right: (
      <Image
        srcImage='https://res-zalo.zadn.vn/upload/media/2024/1/30/auto_fast_messages_1706602819645_54621.png'
        width={550}
        height={440}
        loading='lazy'
        style={{
          maxWidth: '100%',
        }}
      />
    ),
    left: (
      <SolutionInfo
        topDescription='Tư vấn khách nhanh chóng, chuyên nghiệp với'
        title={`Tin nhắn tự động,
        tin nhắn nhanh`}
        btmDescription='Với tin nhắn tự động, bạn có thể trả lời khách hàng ngay cả khi vắng mặt bằng mẫu tin soạn sẵn. Ngoài ra, bạn còn có thể giảm thời gian soạn tin nhắn (nội dung lặp đi lặp lại) với mẫu tin nhắn nhanh.'
      />
    ),
  },
  {
    key: 'SOLUTION-4',
    // isGrey: true,
    // isPushToRight: true,
    right: (
      <SolutionInfo
        topDescription='Kinh doanh hiệu quả hơn với'
        title={`Danh mục sản phẩm`}
        btmDescription='Chỉ cần tạo sản phẩm và bạn có thể lập tức chia sẻ tới khách hàng, không cần soạn lại nội dung mỗi lần tư vấn.'
      />
    ),
    left: (
      <Image
        srcImage='https://res-zalo.zadn.vn/upload/media/2024/1/30/category_1706602881566_462176.png'
        width={550}
        height={440}
        loading='lazy'
        style={{
          maxWidth: '100%',
        }}
      />
    ),
  },
];

const Solution: React.FC<SolutionProps> = () => (
  <div className={owlClass}>
    {ZIC_ZAC_SOLUTION.map(({ key, isGrey, isPushToRight, left, right }) => (
      <div
        key={key}
        className={
          isGrey
            ? `${owlClass}__container--grey ${owlClass}__container`
            : `${owlClass}__container`
        }>
        <div className={`${owlClass}__container__zic-zac`}>
          <div
            className={
              isPushToRight
                ? `${owlClass}__container__zic-zac__left`
                : `${owlClass}__container__zic-zac__left ${owlClass}__container__zic-zac__left--image`
            }>
            {left}
          </div>
          <div
            className={
              isPushToRight
                ? `${owlClass}__container__zic-zac__right ${owlClass}__container__zic-zac__right--image`
                : `${owlClass}__container__zic-zac__right`
            }>
            {right}
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default Solution;
