import { createSlice } from "@reduxjs/toolkit";

import type { ProfileI } from "z-types/profile";
import { RequestErrorTypes } from "constant/request-errors";

import { CODE } from "services/http-common";
import home from "services/home";
import { onFetchSubscription } from "./subscription";
import * as Sentry from "@sentry/react";
import { PC_AGENT } from "constant/home";
import {
  getValueFromSessionStorage,
  setValueToSessionStorage,
} from "utils/local-storage";
import { getApi } from "apis";

interface initialProfileStateI {
  profile: ProfileI | null;
  isLoadingProfile: boolean;
  errorProfile: RequestErrorTypes | boolean;
}

const initialState: initialProfileStateI = {
  profile: null,
  isLoadingProfile: true,
  errorProfile: false,
};

// Slice
const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    onFetching: (state) => {
      state.isLoadingProfile = true;
    },
    fetchedSuccess: (
      state,
      action: {
        payload: ProfileI | null;
      }
    ) => {
      state.profile = action.payload;
      state.isLoadingProfile = false;
      state.errorProfile = false;
    },
    fetchedError: (
      state,
      action: {
        payload: RequestErrorTypes | boolean;
      }
    ) => {
      state.isLoadingProfile = false;
      state.errorProfile = action.payload;
    },

    resetProfile: (state) => {
      state.errorProfile = true;
      state.isLoadingProfile = false;
      state.profile = null;
    },
  },
});

// Actions
const { onFetching, fetchedSuccess, fetchedError, resetProfile } =
  slice.actions;

const isRedirectYet = () => {
  const value = getValueFromSessionStorage("z-redirect");
  return !!value;
};

export const onFetchProfile =
  (redirect = "", logTracking : {flow: string, action: string, params?: string} | null = null) =>
  async (dispatch: any): Promise<void> => {
    dispatch(onFetching());
    await getApi<ProfileI>({
      domain: import.meta.env.VITE_ZBOX_DOMAIN_API,
      path: "/get-user-info",
      credential: true,
      success: (success) => {
        if (success) {
          if (success.error_code !== CODE.SUCCESS) {
            if (
              success.error_code === CODE.NOT_LOGIN 
              && window.navigator.userAgent.includes(PC_AGENT)
            ) {
              if (!isRedirectYet()) {
                setValueToSessionStorage("z-redirect", 1);
                window.location.href =
                  redirect !== ""
                    ? `${import.meta.env.VITE_ZBOX_LOGIN}?redirect=${redirect}`
                    : import.meta.env.VITE_ZBOX_LOGIN!;
              }
            }
            dispatch(fetchedError(true));
            return;
          }
          if (success.data) {
            window.user = success.data?.userId;
            dispatch(fetchedSuccess(success.data));
            home.trackingLog(
              logTracking || {
                flow: "Onboarding",
                action: "sign_in_success",
              }
            );

            if (success.data?.userId) {
              Sentry.setUser({ id: success.data.userId });
              dispatch(onFetchSubscription(success.data.userId));
            }
          } else {
            dispatch(fetchedError(true));
          }
        }
      },
      error: () => {
        dispatch(fetchedError(true));
      },
    });
  };

export const onResetProfile =
  () =>
  async (dispatch: any): Promise<void> => {
    try {
      window.user = "";
      dispatch(resetProfile());
    } catch (_) {
      /* empty */
    }
  };

export default slice.reducer;
