import React from "react";

// components

import "./style.scss";
import Image from "components/image";

interface SectionProps {
  name?: "introduce" | "features" | "solution" | "compare-packages" | "paywall" | "compact";
  topDescription?: React.ReactNode;
  title?: React.ReactNode;
  btmDescription?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const owlClass = "section";

const mapBanner: Record<string, string> = {
  introduce:
    "https://res-zalo.zadn.vn/upload/media/2024/1/22/banner_1705914858100_434195.svg",
  paywall:
    "https://res-zalo.zadn.vn/upload/media/2024/6/6/Header_1717652152596_796302.svg",
};

const Section: React.FC<SectionProps> = ({
  name,
  topDescription,
  title,
  btmDescription,
  children,
  style,
}) => {
  return (
    <div
      className={name ? `${owlClass} ${owlClass}--${name}` : owlClass}
      style={style}
    >
      {["introduce", "paywall"].includes(name ?? "") && (
        <div className="banner">
          <Image srcImage={mapBanner[name!]} style={{ objectFit: "cover" }} />
        </div>
      )}
      {!!topDescription && (
        <div className={`${owlClass}__top-description`}>{topDescription}</div>
      )}
      {!!title && <div className={`${owlClass}__title`}>{title}</div>}
      {!!btmDescription && (
        <div className={`${owlClass}__btm-description`}>{btmDescription}</div>
      )}
      {!!children && <div className={`${owlClass}__content`}>{children}</div>}
    </div>
  );
};

export default Section;
