import type { IconProps } from "./model.js";

const TrustingIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "64"}
    height={size || "64"}
    viewBox="0 0 64 64"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={size || "64"} height={size || "64"} rx="32" fill="white" />
    <rect
      width={size || "64"}
      height={size || "64"}
      rx="32"
      fill="url(#paint0_linear_7104_55624)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.004 34.404C19.131 34.404 16.802 32.075 16.802 29.202C16.802 26.329 19.131 24 22.004 24C24.877 24 27.206 26.329 27.206 29.202C27.206 32.075 24.877 34.404 22.004 34.404ZM22.004 35.5605C26.7923 35.5605 30.674 39.4422 30.674 44.2306V46.5426C30.674 46.8618 30.4153 47.1206 30.096 47.1206H13.912C13.5928 47.1206 13.334 46.8618 13.334 46.5426V44.2306C13.334 39.4422 17.2157 35.5605 22.004 35.5605Z"
      fill="#0068FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.5645 34.404C39.6915 34.404 37.3625 32.075 37.3625 29.202C37.3625 26.329 39.6915 24 42.5645 24C45.4375 24 47.7665 26.329 47.7665 29.202C47.7665 32.075 45.4375 34.404 42.5645 34.404ZM42.5646 35.5605C47.3529 35.5605 51.2346 39.4422 51.2346 44.2306V46.5426C51.2346 46.8618 50.9758 47.1206 50.6566 47.1206H34.4725C34.1533 47.1206 33.8945 46.8618 33.8945 46.5426V44.2306C33.8945 39.4422 37.7762 35.5605 42.5646 35.5605Z"
      fill="#0068FF"
    />
    <g opacity="0.6">
      <path
        d="M32.33 12.1076C32.1253 11.9954 31.8811 11.9954 31.6765 12.1076L27.4043 14.4492C27.2607 14.5278 27.2607 14.7427 27.4043 14.8214L29.2364 15.8256C29.3305 15.8771 29.4443 15.8771 29.5383 15.8254L33.949 13.4026L33.9495 13.4023L34.1528 13.2896C34.2248 13.2496 34.2246 13.146 34.1523 13.1064L32.33 12.1076Z"
        fill="#006AF5"
      />
      <path
        d="M34.7754 13.4479C34.6809 13.3961 34.5665 13.3964 34.4724 13.4486L34.0822 13.665L34.0817 13.6653L29.8603 15.9842C29.7879 16.0239 29.788 16.1279 29.8604 16.1676L31.6765 17.163C31.8811 17.2751 32.1253 17.2751 32.33 17.163L36.6022 14.8214C36.7457 14.7427 36.7457 14.5278 36.6022 14.4492L34.7754 13.4479Z"
        fill="#006AF5"
      />
      <path
        d="M32.2471 21.7676V17.9836C32.2471 17.8692 32.3094 17.7638 32.4096 17.7087L36.69 15.3538C36.8294 15.2771 37 15.378 37 15.5371V19.0738C37 19.3408 36.8547 19.5866 36.6208 19.7153L32.5571 21.9509C32.4177 22.0276 32.2471 21.9267 32.2471 21.7676Z"
        fill="#006AF5"
      />
      <path
        d="M27 15.5377V19.0738C27 19.3408 27.1453 19.5866 27.3792 19.7153L31.4429 21.9509C31.5823 22.0276 31.7529 21.9267 31.7529 21.7676V17.9842C31.7529 17.8698 31.6906 17.7644 31.5904 17.7093L27.31 15.3544C27.1706 15.2777 27 15.3786 27 15.5377Z"
        fill="#006AF5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_7104_55624"
        x1="32"
        y1="64"
        x2="32.1075"
        y2="0.000182708"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3591E6" stopOpacity="0" />
        <stop offset="1" stopColor="#3591E6" stopOpacity="0.2" />
      </linearGradient>
    </defs>
  </svg>
);

export default TrustingIcon;
