import React, { useCallback, useEffect, useMemo, useRef } from "react";

// store
import { useAppDispatch, useAppSelector } from "hooks";
import { RootState, store } from "store";
import { onScrollTo } from "store/home";

// components
import Text from "components/text";
import Button from "components/button";
import NavigationMenu from "components/navigation-menu";
import LogoZbusiness from "components/logo-zbusiness";
import Dropdown from "components/dropdown";
import Spin from "components/spin";

// utils
import useWindowSize from "hooks/use-window-size";
import { BREADCRUMB, DESKTOP_SIZE, SectionTypes } from "constant/home";
import { CODE } from "services/http-common";
import { onFetchProfile, onResetProfile } from "store/profile";

import home from "services/home";

import "./style.scss";
import loginModal from "utils/login-modal";
import { postApi } from "apis";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { onResetSubscription } from "store/subscription";

interface HeaderProps {
  rightIcon: React.ReactNode;
}

const owlClass = "header";

const Header: React.FC<HeaderProps> = ({ rightIcon }) => {
  const dispatch = useAppDispatch();
  // const { sectionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { scrollTo } = useAppSelector((state: RootState) => state.home);
  const initLogin = useRef<boolean>(false);

  const { profile, isLoadingProfile, errorProfile } = useAppSelector(
    (state: RootState) => state.profile
  );
  const { width } = useWindowSize();
  const { openModal } = loginModal();
  const isDesktop = useMemo(() => width > DESKTOP_SIZE, [width]);
  useEffect(() => {
    // dispatch(onFetchProfile());
    // switch (sectionId) {
    //   case "faq":
    //     dispatch(onScrollTo("faq-url" as SectionTypes));
    //     break;
    //   case "solution":
    //     dispatch(onScrollTo("solution-url" as SectionTypes));
    //     break;
    //   case "price":
    //     dispatch(onScrollTo("price-url" as SectionTypes));
    //     break;
    //   default:
    //     break;
    // }
    if (!initLogin.current) {
      store.dispatch(onFetchProfile());
      initLogin.current = true;
    }
  }, []);

  const doLogin = () => {
    const params = {
      from: "click button sign in",
    };
    home.trackingLog({
      flow: "Onboarding",
      action: "click_button_sign_in",
      params: JSON.stringify(params),
    });
    if (location.pathname !== "/") {
      window.location.href =
        (import.meta.env.VITE_ZBOX_LOGIN as string) +
        "?redirect=" +
        import.meta.env.VITE_DOMAIN +
        location.pathname;
    } else {
      window.location.href = import.meta.env.VITE_ZBOX_LOGIN as string;
    }
  };

  const doLogout = () => {
    openModal({
      title: "Đăng xuất",
      description: "Bạn có chắc chắn muốn đăng xuất khỏi zBox?",
      okText: "Đăng xuất",
      onOk: async function () {
        await postApi({
          domain: import.meta.env.VITE_ZBOX_DOMAIN_API,
          path: "/logout",
          body: {
            userId: profile?.userId,
          },
          success: (success) => {
            if (success && success.error_code !== CODE.SUCCESS) {
              if (success?.error_code === 102) {
                dispatch(onResetProfile());
                dispatch(onResetSubscription());
              }
              return;
            }
            dispatch(onResetProfile());
            dispatch(onResetSubscription());
          },
          error: () => {
            dispatch(onResetProfile());
            dispatch(onResetSubscription());
          },
        });
      },
    });
  };

  const onRedeemCode = useCallback(() => {
    home.trackingLog({
      flow: "Onboarding",
      action: "click_button_redeem_code",
    });
    window.location.href = import.meta.env.VITE_ZBOX_DOMAIN + "/activate-code";
  }, []);

  const onClickBreadcrumb = (nav: SectionTypes) => {
    if (location && location.pathname !== "/") {
      navigate("/");
    }
    dispatch(onScrollTo(nav));
  };

  let profileRender;
  if (isLoadingProfile) {
    profileRender = <Spin isSmall />;
  } else {
    if (!errorProfile) {
      profileRender = (
        <Dropdown
          subMenu={[
            {
              item: (
                <Text
                  className={`${owlClass}__text`}
                  style={{
                    width: "100%",
                    padding: "8px",
                    display: "block",
                  }}
                >
                  Đăng xuất
                </Text>
              ),
              onChoose: doLogout,
            },
          ]}
        >
          <img
            width="32"
            height="32"
            className={`${owlClass}__right__avatar`}
            src={profile?.avatar}
            alt="..."
          />
        </Dropdown>
      );
    } else {
      profileRender = (
        <Button type="primary" onClick={doLogin}>
          Đăng nhập
        </Button>
      );
    }
  }

  let menuRender;
  if (isDesktop) {
    menuRender = (
      <>
        <div className={`${owlClass}__right__breadcrumb`}>
          <>
            {BREADCRUMB.map(({ label, navigate: nav }) => (
              <Text
                key={label}
                className={(() => {
                  if (location.pathname !== "/") {
                    return "";
                  }
                  switch (nav) {
                    case "homepage-breadcrumb":
                      return scrollTo === "homepage-logo" ||
                        scrollTo === "homepage-breadcrumb" ||
                        scrollTo === "homepage" ||
                        scrollTo === "homepage-scroll"
                        ? `${owlClass}__right__breadcrumb__activate`
                        : "";

                    case "solution-breadcrumb":
                      return scrollTo === "solution-breadcrumb" ||
                        scrollTo === "solution-scroll" ||
                        scrollTo === "solution-url"
                        ? `${owlClass}__right__breadcrumb__activate`
                        : ``;

                    case "price-breadcrumb":
                      return scrollTo === "price-breadcrumb" ||
                        scrollTo === "price-scroll" ||
                        scrollTo === "price-url" ||
                        scrollTo === "explore-homepage" ||
                        scrollTo === "explore-faq"
                        ? `${owlClass}__right__breadcrumb__activate`
                        : ``;

                    case "faq-breadcrumb":
                      return scrollTo === "faq-breadcrumb" ||
                        scrollTo === "faq-scroll" ||
                        scrollTo === "faq-url"
                        ? `${owlClass}__right__breadcrumb__activate`
                        : ``;

                    default:
                      break;
                  }
                })()}
                onClick={() => {
                  onClickBreadcrumb(nav as SectionTypes);
                }}
              >
                {label}
              </Text>
            ))}
            <NavLink
              to="/mua-code"
              style={{ textDecoration: "none", color: "#1a1a1a" }}
              className={({ isActive }) =>
                isActive
                  ? `${owlClass}__right__breadcrumb__activate text`
                  : "text"
              }
            >
              Mua code
            </NavLink>
          </>
        </div>
        <Button type="secondary" onClick={onRedeemCode}>
          Kích hoạt code
        </Button>
      </>
    );
  } else {
    menuRender = <NavigationMenu>{rightIcon}</NavigationMenu>;
  }

  return (
    <div className={owlClass}>
      <div className={`${owlClass}__left`}>
        <LogoZbusiness />
      </div>
      <div className={`${owlClass}__right`}>
        {isDesktop && menuRender}
        {profileRender}
        {!isDesktop && menuRender}
      </div>
    </div>
  );
};

export default Header;
