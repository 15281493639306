import { useState, useEffect, useRef } from "react";

export default function useLongPress(callback = () => {}, ms = 300, startMs = ms) {
  const [startLongPress, setStartLongPress] = useState(false);
  const isFirstTime = useRef<boolean>(false);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout> | null = null;
    if (startLongPress) {
      if (!isFirstTime.current) {
        timerId = setTimeout(callback, startMs);
        isFirstTime.current = true;
      } else {
        timerId = setTimeout(callback, ms);
      }
    } else {
      if (timerId) {
        clearTimeout(timerId);
      }
      isFirstTime.current = false;
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [callback, ms, startLongPress, startMs]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
}
