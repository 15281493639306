import { createSlice } from "@reduxjs/toolkit";
import { SectionTypes } from "constant/home";

interface initialHomeStateI {
  scrollTo: SectionTypes;
}

const initialState: initialHomeStateI = {
  scrollTo: (new URLSearchParams(window.location.search).get("scroll_to") ||
    "homepage-breadcrumb") as SectionTypes,
};

// Slice
const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    scrollTo: (
      state,
      action: {
        payload: SectionTypes;
      }
    ) => {
      state.scrollTo = action.payload;
    },
  },
});

// Actions
const { scrollTo } = slice.actions;

export const onScrollTo =
  (position: SectionTypes) =>
    async (dispatch: any): Promise<void> => {
      dispatch(scrollTo(position));
    };

export default slice.reducer;
