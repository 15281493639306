import type { IconProps } from "./model.js";

const ErrorIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "93"}
    height={size || "93"}
    viewBox="0 0 93 93"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="46.0537" cy="46.0889" r="46" fill="#FDF1F1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.0777 79.4663C64.4983 79.4663 79.4311 64.5335 79.4311 46.1129C79.4311 27.6923 64.4983 12.7595 46.0777 12.7595C27.6572 12.7595 12.7244 27.6923 12.7244 46.1129C12.7244 64.5335 27.6572 79.4663 46.0777 79.4663ZM42.524 30.0611C42.4579 28.1221 44.0118 26.5143 45.9519 26.5143H46.2041C48.1442 26.5143 49.6981 28.1221 49.632 30.0611L48.9149 51.0974C48.8699 52.4172 47.7869 53.4639 46.4663 53.4639H45.6897C44.3691 53.4639 43.2861 52.4172 43.2411 51.0974L42.524 30.0611ZM50.0803 62.1409C50.0803 64.3514 48.2884 66.1433 46.0779 66.1433C43.8675 66.1433 42.0755 64.3514 42.0755 62.1409C42.0755 59.9305 43.8675 58.1385 46.0779 58.1385C48.2884 58.1385 50.0803 59.9305 50.0803 62.1409Z"
      fill="#D91B1B"
    />
  </svg>
);

export default ErrorIcon;
