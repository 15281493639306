function isNumeric(num: string): boolean {
  return Number.isNaN(num);
}

// Get value from session storage
export const getValueFromSessionStorage = (key: string): any => {
  try {
    const value = sessionStorage.getItem(key);

    if (value !== null) {
      if (isNumeric(value)) {
        return parseInt(value);
      }
      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
    }
  } catch (err) {
    // ignored
  }
  return null;
};

// Set value to session storage
export const setValueToSessionStorage = (key: string, value: any): boolean => {
  try {
    if (typeof value === "number" || typeof value === "string") {
      sessionStorage.setItem(key, `${value}`);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
    return true;
  } catch (error) {
    console.error(`Set key ${key} failed: ${error}`);
  }
  return false;
};

// Remove value from session storage
export const removeValueFromSessionStorage = (key: string): void => {
  sessionStorage.removeItem(key);
};
