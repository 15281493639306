import { useContext } from "react";
import { NotifyContext } from "../context/Notify";
import { NotifyProviderProps } from "context/model";

const useNotify = () => {
  const context = useContext<NotifyProviderProps | null>(NotifyContext);

  if (context === null) {
    throw new Error("useNotify must be used within a NotifyProvider");
  }
  return context;
};

export default useNotify;
