import type { IconProps } from "./model.js";

const TooltipIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "18"}
    height={size || "18"}
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9ZM15.2755 9C15.2755 5.53413 12.4659 2.72449 9 2.72449C5.53413 2.72449 2.72449 5.53413 2.72449 9C2.72449 12.4659 5.53413 15.2755 9 15.2755C12.4659 15.2755 15.2755 12.4659 15.2755 9ZM9 8.38776C9.31212 8.38776 9.5697 8.62132 9.60748 8.9232L9.61225 9V12.9359C9.61225 13.274 9.33813 13.5481 9 13.5481C8.68788 13.5481 8.43031 13.3145 8.39253 13.0127L8.38776 12.9359V9C8.38776 8.66187 8.66187 8.38776 9 8.38776ZM9 6.54009C9.54343 6.54009 9.98396 6.09955 9.98396 5.55612C9.98396 5.01269 9.54343 4.57216 9 4.57216C8.45657 4.57216 8.01604 5.01269 8.01604 5.55612C8.01604 6.09955 8.45657 6.54009 9 6.54009Z"
      fill="#1A1A1A"
      fillOpacity="0.6"
    />
  </svg>
);

export default TooltipIcon;
