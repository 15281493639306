import type { IconProps } from "./model.js";

const ChevronDownIcon = ({ size, ...props }: IconProps) => (
  <svg
    width={size || "32"}
    height={size || "32"}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1015 12.7685C8.41392 12.4561 8.92045 12.4561 9.23287 12.7685L16.0005 19.5361L22.7682 12.7685C23.0806 12.4561 23.5871 12.4561 23.8995 12.7685C24.212 13.0809 24.212 13.5874 23.8995 13.8999L16.5662 21.2332C16.2538 21.5456 15.7473 21.5456 15.4348 21.2332L8.1015 13.8999C7.78908 13.5874 7.78908 13.0809 8.1015 12.7685Z"
        fill="white"
      />
    </g>
  </svg>
);

export default ChevronDownIcon;
