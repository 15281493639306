import React from "react";

import "./style.scss";
import Text from "components/text";
import { TooltipIcon } from "icons";

interface TooltipProps {
  content: React.ReactNode;
}

const owlClass = "tooltip";

const Tooltip: React.FC<TooltipProps> = ({ content }) => (
  <div className={owlClass}>
    <TooltipIcon />
    <div className={`${owlClass}__content`}>
      <Text color="white">{content}</Text>
    </div>
  </div>
);

export default Tooltip;
