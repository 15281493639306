import React, { useMemo } from "react";

import "./style.scss";

type Type = "primary" | "" | "ghost" | "basic" | "secondary" | "disable" | "ghost-primary";
type Size = "small" | "medium" | "semi-large" | "large" | "x-large";

interface ButtonProps {
  type?: Type;
  size?: Size;
  block?: boolean;
  disabled?: boolean;
  bold?: boolean;
  children: React.ReactNode;

  className?: string;
  style?: React.CSSProperties;
  onClick?: any;
}

const owlClass = "btn";

const Button: React.FC<ButtonProps> = ({
  type,
  size,
  className,
  disabled,
  block,
  style,
  children,
  onClick,
  bold,
}) => {
  const styleClass = useMemo(() => {
    let style = owlClass;
    if (type) {
      style = style + ` ${owlClass}--${type}`;
    }
    if (size) {
      style = style + ` ${owlClass}--${size}`;
    }
    if (block) {
      style = style + ` ${owlClass}--block`;
    }
    if (disabled) {
      style = style + ` ${owlClass}--disabled`;
    }
    if (bold) {
      style = style + ` ${owlClass}--bold`;
    }

    if (className) {
      style = style + ` ${className}`;
    }

    return style;
  }, [type, size, block, className, disabled, bold]);
  return (
    <button style={style} className={styleClass} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
