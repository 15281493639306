import React, { useRef } from "react";
import { createPortal } from "react-dom";

// components
import Text from "components/text";

// utils
import { useOnClickOutside } from "hooks/use-click-outside";
import useEscape from "hooks/use-esc";

import "./style.scss";
import Image from "components/image";
import cx from "utils/cx";

interface ModalProps {
  isShow: boolean;

  isShowCloseIcon?: boolean;
  isHalfWidth?: boolean;

  isFullMobileScreen?: boolean;
  style?: React.CSSProperties;

  title?: React.ReactNode;
  body?: React.ReactNode;
  footer?: Array<React.ReactNode>;
  compact?: boolean;

  onClose: () => void;
}

const owlClass = "modal";

const Modal: React.FC<ModalProps> = ({
  isShow,
  isFullMobileScreen,
  style,
  title,
  body,
  footer,
  onClose,
  compact = false
}) => {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    const pLibModalEl = document.getElementById("p-lib-modal");
    if (pLibModalEl) {
      return;
    }

    onClose();
  });

  useEscape(() => onClose());

  if (!isShow) return null;

  return createPortal(
    <div className={owlClass}>
      <div
        className={(() => {
          let style = `${owlClass}__content`;

          if (isFullMobileScreen) {
            style += ` ${owlClass}__content--is-full-mobile-screen`;
          }
          return style;
        })()}
        ref={ref}
        style={style}
      >
        {!!title && (
          <div className={`${owlClass}__content__header`}>
            <Text size="xlg-36" weight="bold" center>
              {title}
            </Text>
            <div className={`${owlClass}__content__header__close-icon`}>
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/1/30/icon_close_1706600363317_450403.svg"
                width={24}
                height={24}
                onClick={onClose}
              />
            </div>
          </div>
        )}
        {body && (
          <div className={cx(`${owlClass}__content__body`, compact && `${owlClass}__content__body__compact`)}>
            {!title && (
              <div className={`${owlClass}__content__body__close-icon`}>
                <Image
                  srcImage="https://res-zalo.zadn.vn/upload/media/2024/1/30/icon_close_1706600363317_450403.svg"
                  width={24}
                  height={24}
                  onClick={onClose}
                />
              </div>
            )}
            {body}
          </div>
        )}
        {!!footer?.length && (
          <div className={`${owlClass}__content__footer`}>{footer}</div>
        )}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
